import {FormGroup, FormControlLabel, Switch,  Card, CardMedia,Button, Chip, CircularProgress, Container, Grid, Paper, TextField, Typography, RadioGroup, Radio, FormControl, FormLabel } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { live,img_link } from '../../App'
import './eventDash.css'
import New_Explore_Dash from './newExploreD'
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CategoriesMain, Categories_Explore, Events_Mode, Events_Platform, Events_Type } from '../homepage/homeData';
import { TimePicker } from '@mui/x-date-pickers';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {useCookies} from 'react-cookie'

import { MyEditorS, MyEditorS2 } from './editor';

function ExploreDash() {
  const [data,setData]=useState({})

  const [newE,setNewE]=useState(false)
  const [reload,setReload]=useState(0)
  const [edit,setEdit]=useState('')
  const [text,setText]=useState('')
  const [text1,setText1]=useState('')
  const [editor,setEditor]=useState('')
  const [active,setActive]=useState('')
  const [active1,setActive1]=useState(false)
  const [activeI,setActiveI]=useState('')
  const[ratingE,setRatingE]=useState(null)

const [activeVal1,setActiveVal1]=useState('')
const [error,setError]=useState('')
const [activeS,setActiveS]=useState('')
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)
const [editor1,setEditor1]=useState('')
const[auth,setAuth]=useCookies(['auth-happ'])
const [explore_id,setExploreID]=useState('')
const [redeem,setRedeem]=useState('Coupon Code')

const [del,setDel]=useState(false)

    const [processing,setProcessing]=useState(true)
    const [explore,setExplore]=useState([])
    useEffect(() => {
        fetch(live+'explore').then((res)=>res.json()).then((resp)=>exploreResp(resp))
        }, [reload])


        
var dd = data&&data.start_date?String(data.start_date.$d.getDate()).padStart(2, '0'):'';
var mm = data&&data.start_date?String(data.start_date.$d.getMonth() + 1).padStart(2, '0'):''; //January is 0!
var yyyy = data&&data.start_date?data.start_date.$d.getFullYear():'';

let today = yyyy+'-'+mm+'-'+dd;

var dd1 = data&&data.end_date?String(data.end_date.$d.getDate()).padStart(2, '0'):'';
var mm1 = data&&data.end_date?String(data.end_date.$d.getMonth() + 1).padStart(2, '0'):''; //January is 0!
var yyyy1 = data&&data.end_date?data.end_date.$d.getFullYear():'';

let today1 = yyyy1+'-'+mm1+'-'+dd1;

var dd2 = data&&data.expiry_date?String(data.expiry_date.$d.getDate()).padStart(2, '0'):'';
var mm2 = data&&data.expiry_date?String(data.expiry_date.$d.getMonth() + 1).padStart(2, '0'):''; //January is 0!
var yyyy2 = data&&data.expiry_date?data.expiry_date.$d.getFullYear():'';

let today2 = yyyy2+'-'+mm2+'-'+dd2;
let time1=data.start_time?(data.start_time.$d.getHours()+':'+data.start_time.$d.getMinutes()):'';
let time2=data&&data.end_time?(data.end_time.$d.getHours()+':'+data.end_time.$d.getMinutes()):'';







const Delete=(val)=>{

  
  if(explore_id){
    setOpen2(false)
    setDel(true)
    const formdata=new FormData()
      formdata.append(`explore_id`,explore_id)
    fetch(live+'explore',
    {
      method:'Delete',
      body:formdata
    }).then(res=>res.json()).then((resp)=>delRes(resp))
  
  
  
  }
  else{
    setExploreID(val)
  setOpen2(true)
  }
  }
  
  const delRes=(resp)=>{
    if(resp.status){
  setDel(false)
  setError(resp.detail)
  setReload(reload+1)
  setExploreID('')

  setOpen1(true)
    }
    else{
      setDel(false)
  
      setError(resp.detail)
      setExploreID('')

      setOpen(true)
  
    }
  
  }


        const exploreResp=(val)=>{
            setProcessing(false)
            setExplore(val.data)
        
        
        }


        useEffect(() => {

          if(reload>0&&edit)
          fetch(live+`explore?q=${edit.explore_id}`).then((res)=>res.json()).then((resp)=>eventResp1(resp))
          }, [reload]) 

          const eventResp1=(val)=>{
            setProcessing(false)
            setEdit(val.data[0])
          
          
          }

          const editRes=(res)=>{
            if(res.status){
              setOpen1(true)
              setReload(reload+1)
              setActive('')
              setError(res.detail)
              Cancel()
            }
            else{
              setError(res.detail)
              setOpen(true)
              setActive('')

              Cancel()
            
            
            }
            }


            const renderActive = (val) => {
              return (
                  <strong>
                    {val.row.Active?<i  className="fas fa-check-circle icon_review"></i>:<i  className="fas fa-times-circle icon_review"></i>}
                  </strong>
              )
            }
    const rows= explore.map((item,k) => {
        return  {
         exploreID:item.explore_id,
    
          id:k+1,
          Name: item.explore_name,
          Organiser: item.explore_organiser,
          Category: item.category,
          "Explore Type":item.explore_type,
          "Explore Mode":item.explore_mode,
          "Explore Url":item.explore_url,

          Start:item.start_date,
          Finish:item.end_date,
          "Created By":item.created_by,
          "Address":item.addressLine1,
          "City":item.city,
          "State":item.stat,
          "Country":item.country,
          "Pincode":item.pincode,
          "Created On":item.created_on,
          "Active":item.is_active
    
    
    
          
    
    
    
         
        }
    }
      )
      const renderEdit = (val) => {
        return (
            <strong>
               <Button  onClick={()=>Edit(val.row.exploreID)} className='btn_evd2'>Edit</Button>
            </strong>
        )
      }
      const renderDelete = (val) => {
        return (
            <strong>
                       <Button onClick={()=>Delete(val.row.exploreID)} className='btn_evd3'>{explore_id===val.row.exploreID&&del?'Deleting':'Delete'}</Button>
      
               {/* <Button onClick={()=>Delete(val.rowexploretID)} className='btn_evd3'>Delete</Button> */}
            </strong>
        )
      }

      const EditS=(val,val2,val3,val4,val5)=>{
        if(active===val&&activeS===val2){
        EditT(val,'',val4,val5)
        }
        else{
        
          setActive(val)
          setActiveS(val2)
          setText(val3)}
        
        }
        const EditT=(val,val2,val3,val4)=>{
        
          if(active===val){
        
        if(active==='explore_url'){
          checkUrl()
        }
        else{
        
          setProcessing(true)
            const formdata=new FormData()
            formdata.append(`active`,active) 
            formdata.append('val', active==='show_likes_admin'&&data.checkedS?'1':active==='show_likes_admin'&&!data.checkedS?'0':active==='active'&&data.checkedS?'1':active==='active'&&!data.checkedS?'0':active==='start_date'?today:active==='end_date'?today1:active==='start_time'?time1:active==='end_time'?time2:activeS==='platform'?activeVal1:activeS==='url'?val4:text&&!text1?text:activeVal1) 
            formdata.append('explore_id',edit.explore_id) 
            formdata.append('sub_id',val3?val3:'') 
            formdata.append('text1',activeS==='url'?text:activeS==='platform'?val4:text1) 
            formdata.append('img_active',active.includes('imageB')&&data.checkedS?'1':'0') 

        
        
        
        
        fetch(live+'explore',
              {
                method:'PUT',
                body:formdata
              }).then(res=>res.json()).then((resp)=>editRes(resp))
        
        
        }
        
            
        
        
          }
        
          else{
            setActive(val)
            setText(val2)
          }
          
        
        }
        const changeSwitch=(val,val1)=>{

          setActive(val)
          setData({
            ...data,checkedS:val1
          })
        
      
      }
      const Edit=(id)=>{
        setEdit(explore.find(val=>val.explore_id===id))
      }
    const columns = [
      { field: 'id', headerName: 'S No.', width: 90 },
      { field: 'exploreID', headerName: 'Explore ID' },
    
      {
        field: 'Name',
        headerName: 'Name',
       
      },
      {
        field: 'Organiser',
        headerName: 'Organiser',
      
      },
      {
        field: 'Category',
        headerName: 'Category',
       
      },
      {
        field: 'Explore Type',
        headerName: 'Explore Type',
       
      }, {
        field: 'Explore Mode',
        headerName: 'Explore Mode',
       
      },{
        field: 'Explore Url',
        headerName: 'Explore Url',
       
      },
       {
        field: 'Start',
        headerName: 'Start',
       
      }, {
        field: 'Finish',
        headerName: 'Finish',
       
      },
      {
        field: 'Created By',
        headerName: 'Created By',
       
      },
      {
        field: 'Address',
        headerName: 'Address',
       
      },
      {
        field: 'City',
        headerName: 'City',
       
      },
      {
        field: 'State',
        headerName: 'State',
       
      },
      {
        field: 'Country',
        headerName: 'Country',
       
      },
    
      {
        field: 'Pincode',
        headerName: 'Pincode',
       
      },
    
      {
        field: 'Created On',
        headerName: 'Created On',
       
      },
    
    
      {
        field: 'Active',
        headerName: 'Entity Active',
        sortable: false,
        renderCell: renderActive,
        width: 160,
         },
    
    
    
    
      {
        field: 'Edit',
        headerName: ' ',
        sortable: false,
        renderCell: renderEdit,
        width: 160,
         },
         {
          field: 'Delete',
          headerName: ' ',
          sortable: false,
          renderCell: renderDelete,
          width: 160,
           },
    ];

    const Cancel=()=>{
      setActive('')
      setText('')
      setActiveVal1('')
      setActive1(false)
      setActiveI('')
      setData('')
      setEditor('')
      setActiveS('')
      setText1('')
      setRatingE()
      setEditor1('')
    
    
    
    }



    const checkUrl=()=>{
      setProcessing(true)
    
      if(text){
        fetch(live+`explore-url?q=${text}`).then((res)=>res.json()).then(resp=>urlRes(resp))
    
      }
    }
    const urlRes=(val)=>{
      if(val.status){
    setError('')
    changeUrl()
    
      }
      else{
        setProcessing(false)
        setError(val.detail)
        setOpen(true)
      }
    }
    

    const Editor=(val)=>{
      if(editor){
        setProcessing(true)
        const formdata=new FormData()
        formdata.append(`active`,'editor') 
        formdata.append('val',data.overview) 
        formdata.append('explore_id',edit.explore_id) 
        formdata.append('sub_id','') 
    
    
    
    fetch(live+'explore',
          {
            method:'PUT',
            body:formdata
          }).then(res=>res.json()).then((resp)=>editRes(resp))
    
      }
      else{
        setEditor(val)
      }
    
    }

    const Editor1=(val)=>{
      if(editor1){
        setProcessing(true)
        const formdata=new FormData()
        formdata.append(`active`,'editor1') 
        formdata.append('val',data.blog) 
        formdata.append('explore_id',edit.explore_id) 
        formdata.append('sub_id','') 
    
    
    
    fetch(live+'explore',
          {
            method:'PUT',
            body:formdata
          }).then(res=>res.json()).then((resp)=>editRes(resp))
    
      }
      else{
        setEditor1(val)
      }
    
    }

    const changeUrl=()=>{
      const formdata=new FormData()
      formdata.append(`active`,active) 
      formdata.append('val',text) 
      formdata.append('explore_id',edit.explore_id) 
      formdata.append('sub_id','') 
      formdata.append('text1','') 
    
    
    
    
    fetch(live+'explore',
        {
          method:'PUT',
          body:formdata
        }).then(res=>res.json()).then((resp)=>editRes(resp))
    
    
    
    }

    const quillChange=(val)=>{
      setData(data => ({ ...data, overview:val}))
      
     }
     const quillChange1=(val)=>{
      setData(data => ({ ...data, blog:val}))
      
     }

    const handleClose=()=>{
      setOpen(false)
      setOpen1(false)
      setOpen2(false)
      setExploreID('')
    
    
    }
    const dropDownVal=(val)=>{
      setActiveVal1(val)
      setText('')
      setActive1(false)
    
    }


    const ImageCover1=(img,val)=>{

  
      
      if(img&&img.name)
    
    {    if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
      {
        
        if(img.size/1048576 <4){
          setActiveI(val)
    setData({...data,image:img})  
    
      }
         else{
           setError('File should be <4MB')
           setOpen(true)
         }
       } 
       else{
         setError('Upload jpg|jpeg|png format image')
         setOpen(true)
    
       }}
     
    }
    const Reload=(val)=>{
      if(val){
        setProcessing(false)
        setError(val)
        setOpen1(true)
          setReload(reload+1)
          setNewE(false)
      }
      else{
        setProcessing(false)
        setReload(reload+1)
      }
    }
    const EditImage=(val)=>{
      if(data.image&&activeI){
    
        setProcessing(true)
        const formdata=new FormData()
        formdata.append(`active`,'image') 
        formdata.append('val',data.image) 
        formdata.append('explore_id',edit.explore_id) 
        formdata.append('sub_id',val?val:'') 
    
    
       
    fetch(live+'explore',
          {
            method:'PUT',
            body:formdata
          }).then(res=>res.json()).then((resp)=>editRes(resp))
    
      }
    }
    const eventChange=()=>{
      setNewE(false)
      setReload(reload+1)
    
    }
    
    const EditBack=(val)=>{
      setReload(reload+1)
    
      setEdit(val)
    }


    const EditOffer=(val,val2)=>{
if(val2){
  setRedeem(val2.coupon_redeem?val2.coupon_redeem:redeem)
  
  setData({
    ...data,offer_name:val2.offer_name,offer_heading:val2.offer_heading,offer_description:val2.offer_description,coupon:val2.coupon,url:val2.url,checkedS:val2.is_active?val2.is_active:false
  })
  EditOffer1(val,val2)
}
else{
  EditOffer1(val,val2)

}
    }
const EditOffer1=(val,val2)=>{
  if(active===val){



    setProcessing(true)
    const formdata=new FormData()
    formdata.append(`active`,active) 
    formdata.append('offer_name',data.offer_name)
    formdata.append('offer_heading',data.offer_heading)
    formdata.append('offer_description',data.offer_description)
    formdata.append('offer_expiry',data.expiry_date&&data.expiry_date.$d?today2:val2.offer_expiry)
    formdata.append('is_active',data.checkedS?'1':'0') 

    formdata.append('coupon_redeem',redeem)
    formdata.append('coupon',data.coupon?data.coupon:'')
    formdata.append('url',data.url?data.url:'')


    formdata.append('explore_id',edit.explore_id) 
    formdata.append('sub_id',val2&&val2.id?val2.id:'') 




fetch(live+'explore',
      {
        method:'PUT',
        body:formdata
      }).then(res=>res.json()).then((resp)=>editRes(resp))



  }
  else{
    setActive(val)
  }
}



const EditReview=(val,val2)=>{
  if(val2){
    setData({
      ...data,fname:val2.user_fname,lname:val2.user_lname,review:val2.review,review_detail:val2.review_detail,checkedS:val2.is_verified
    })
    setRatingE(val2.ratings)
    EditReview1(val,val2)
  }
  else{
    EditReview1(val,val2)
  
  }
      }
  const EditReview1=(val,val2)=>{
    if(active===val){
  
  
  
      setProcessing(true)
      const formdata=new FormData()
      formdata.append(`active`,active) 
      formdata.append('user_fname',data.fname)
      formdata.append('user_lname',data.lname)
      formdata.append('review',data.review)
      formdata.append('review_detail',data.review_detail)
      formdata.append('ratings',ratingE)
      formdata.append('val',data.checkedS?'1':'0')

      formdata.append('user_id',auth['auth-happ'].user_id)


      formdata.append('explore_id',edit.explore_id) 

      formdata.append('sub_id',val2&&val2.id?val2.id:'') 
  
  
  
  
  fetch(live+'explore',
        {
          method:'PUT',
          body:formdata
        }).then(res=>res.json()).then((resp)=>editRes(resp))
  
  
  
    }
    else{
      setActive(val)
    }
  }
const Redeem=(val)=>{
  setRedeem(val)
}

const DataR=(val)=>{
  if(redeem==='Coupon Code'){
    setData({
      ...data,coupon:val
    })}
    else{
      setData({
        ...data,url:val
      })
    
  }

}
  return (
    <div>
      <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Delete()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Delete event permanently
</AlertTitle>

      </Alert>
     
     
      </Snackbar>
     {newE&&!edit?<New_Explore_Dash Reload={Reload}  eventChange={eventChange}/>: edit? <Grid style={{minHeight:'80vh',paddingBottom:'5vh',paddingTop:'17vh',alignContent:'flex-start'}} container>
     <Grid item style={{marginBottom:'2vh',textAlign:'end',paddingRight:20}} sm={12} xs={12}>
     <Chip style={{marginBottom:'2vh'}} onClick={()=>EditBack(false)}   className='chip_evd' label={'Back'} />

     </Grid> 
    <Grid item style={{marginBottom:'2vh'}} sm={6} xs={6}>
     <Typography className='txt_evd'>
   
   Edit {edit.explore_organiser} Entity
     </Typography>
     </Grid> 
     <Grid style={{textAlign:'center'}} container sm={6} xs={6}>
     <Grid style={{textAlign:'center'}} item sm={8} xs={8}>
     <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active==='active'?data.checkedS:edit.is_active)}  onChange={(e)=>changeSwitch('active',e.target.checked)} />} label="Active" />
    </FormGroup>
    </Grid> 

    {  active==='active'? <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('active',edit.active)}   className='chip_evd' label={active==='active'&&processing?'Saving':active==='active'&&!processing? 'Save':'Edit Event Name'} />
    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />

    
     </Grid>:null}

     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='explore_name'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.explore_name}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('explore_name',edit.explore_name)}   className='chip_evd' label={active==='explore_name'&&processing?'Saving':active==='explore_name'&&!processing? 'Save':'Edit Entity Name'} />
{  active==='explore_name'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 


     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='explore_description'? <TextField multiline minRows={3} value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.explore_description}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('explore_description',edit.explore_description)}   className='chip_evd' label={active==='explore_description'&&processing?'Saving':active==='explore_description'&&!processing? 'Save':'Edit Entity Description'} />
{  active==='explore_description'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 





     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
 {active==='category'?    <Grid     item sm={12} xs={12}>
  <Chip

  label={!activeVal1?edit.category:activeVal1}
  onClick={()=>setActive1(!active1)}
  onDelete={()=>setActive1(!active1)}
  deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
  className='chip_drop'
/>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Categories_Explore.map((val,idx)=>
  <li onClick={()=>dropDownVal(val.category)} key={idx} className="list_filter">
  {val.category}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>:

     <Typography className='value_evd'>
   {edit.category}
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>EditT('category',edit.category)}  label={active==='category'&&processing?'Saving':active==='category'&&!processing?'Save':'Edit Entity Category'} />
     {  active==='category'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
 {active==='explore_type'?    <Grid     item sm={12} xs={12}>
  <Chip

  label={!activeVal1?edit.explore_type:activeVal1}
  onClick={()=>setActive1(!active1)}
  onDelete={()=>setActive1(!active1)}
  deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
  className='chip_drop'
/>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>:

     <Typography className='value_evd'>
   {edit.explore_type}
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>EditT('explore_type',edit.explore_organiser)}  label={active==='explore_type'&&processing?'Saving':active==='explore_type'&&!processing?'Save':'Edit Entity Type'} />
     {  active==='explore_type'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
 {active==='explore_mode'?    <Grid     item sm={12} xs={12}>
  <Chip

  label={!activeVal1?edit.explore_mode:activeVal1}
  onClick={()=>setActive1(!active1)}
  onDelete={()=>setActive1(!active1)}
  deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
  className='chip_drop'
/>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Mode.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>:

     <Typography className='value_evd'>
   {edit.explore_mode}
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>EditT('explore_mode',edit.explore_organiser)}  label={active==='explore_mode'&&processing?'Saving':active==='explore_mode'&&!processing?'Save':'Edit Entity Mode'} />
     {  active==='explore_mode'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='explore_organiser'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.explore_organiser}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('explore_organiser',edit.explore_organiser)}   className='chip_evd' label={active==='explore_organiser'&&processing?'Saving':active==='explore_organiser'&&!processing? 'Save':'Edit Entity Organiser'} />
{  active==='explore_organiser'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item style={{textAlign:activeI==='image'&&data.image? '-webkit-center':'justfy'}} sm={8} xs={8}>


   {activeI==='image'&&data.image? <Card  sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={URL.createObjectURL(data.image)}
        alt="Entity"
      />
      </Card>:  <Typography className='text1_evd'>
  Entity Images
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',display:'flex'}} xs={4}>
     <div onClick={()=>EditImage()}  style={{display:'flex', justifyContent:'space-around' , backgroundColor: 'var(--pinkColor)',borderRadius:'30px',height:'33px',width:'56%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image&&activeI===`image`&&processing?'Saving':data.image&&activeI===`image`&&!processing?'Save':'Add New Image'}</label>
                  </div>

                  <input type={data.image?'button':'file'} id={`file`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0],`image`)}/><br />
         
                  {  activeI===`image`? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
  
    
     </Grid>
     </Grid> 
     {edit.images.map((val,idx)=><Grid key={idx} container style={{paddingTop:'3vh'}} sm={12} xs={12}>
   
    { idx>0?<><Grid item style={{marginBottom:'2vh'}} sm={6} xs={6}>
    
    </Grid> 
    <Grid style={{textAlign:'center'}} container sm={6} xs={6}>
    <Grid style={{textAlign:'center'}} item sm={8} xs={8}>
    <FormGroup  className='form_switch' >
     <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active===`imageB${idx}`?data.checkedS:val.is_active)}  onChange={(e)=>changeSwitch(`imageB${idx}`,e.target.checked)} />} label={`Active Image${idx+1}` }/>
   </FormGroup>
   </Grid> 

   {  active===`imageB${idx}`? <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
    <Chip  onClick={()=>EditT(`imageB${idx}`,val.is_active,val.id)}   className='chip_evd' label={active===`imageB${idx}`&&processing?'Saving':active===`imageB${idx}`&&!processing? 'Save':'Active Image'} />
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />

   
    </Grid>:null}

    </Grid></> :null}
   
     <Grid  style={{textAlign:'-webkit-center'}}item sm={8} xs={8}>
     <Card  sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={activeI===`image${idx}`&&data.image?URL.createObjectURL(data.image):img_link+val.image}
        alt="Entity"
      />
      </Card>
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center',display:'flex'}} xs={4}>
     <div  onClick={()=>EditImage(val.id)} style={{justifyContent:'space-around' , backgroundColor: 'var(--pinkColor)',borderRadius:'30px',height:'40%',width:'56%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file${idx}`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image&&activeI===`image${idx}`&&processing?'Saving':data.image&&activeI===`image${idx}`&&!processing?'Save':`Edit Image${idx+1}`}</label>
                  </div>

                  <input type={data.image?'button':'file'} id={`file${idx}`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0],`image${idx}`,val.id)}/><br />
         
                  {  activeI===`image${idx}`? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> )}
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='start_date'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Entity Start Date"
        value={data.start_date?data.start_date:new Date(edit.start_date)}
        onChange={(newValue) => {
          setData({...data,start_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.start_date}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('start_date',edit.start_date)}   className='chip_evd' label={active==='start_date'&&processing?'Saving':active==='start_date'&&!processing? 'Save':'Edit Start Date'} />
{  active==='start_date'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='end_date'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Entity End Date"
        value={data.end_date?data.end_date:new Date(edit.end_date)}
        onChange={(newValue) => {
          setData({...data,end_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.end_date}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('end_date',edit.end_date)}   className='chip_evd' label={active==='end_date'&&processing?'Saving':active==='end_date'&&!processing? 'Save':'Edit End Date'} />
{  active==='end_date'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='start_time'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Entity Start Time"
        value={data.start_time?data.start_time:new Date(edit.start_time)}
        onChange={(newValue) => {
          setData({...data,start_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.start_time}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('start_time',edit.start_time)}   className='chip_evd' label={active==='start_time'&&processing?'Saving':active==='start_time'&&!processing? 'Save':'Edit Start Time'} />
{  active==='start_time'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='end_time'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Entity End Time"
        value={data.end_time?data.end_time:new Date(edit.end_date)}
        onChange={(newValue) => {
          setData({...data,end_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.end_time}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('end_time',edit.end_time)}   className='chip_evd' label={active==='end_time'&&processing?'Saving':active==='end_time'&&!processing? 'Save':'Edit End Time'} />
{  active==='end_time'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid>  
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='addressLine1'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.addressLine1}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('addressLine1',edit.addressLine1)}   className='chip_evd' label={active==='addressLine1'&&processing?'Saving':active==='addressLine1'&&!processing? 'Save':'Edit Entity Address'} />
{  active==='addressLine1'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='city'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.city}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('city',edit.city)}   className='chip_evd' label={active==='city'&&processing?'Saving':active==='city'&&!processing? 'Save':'Edit City'} />
{  active==='city'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='stat'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.stat}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('stat',edit.stat)}   className='chip_evd' label={active==='stat'&&processing?'Saving':active==='stat'&&!processing? 'Save':'Edit Stat'} />
{  active==='stat'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='country'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.country}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('country',edit.country)}   className='chip_evd' label={active==='country'&&processing?'Saving':active==='country'&&!processing? 'Save':'Edit Country'} />
{  active==='country'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='pincode'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.pincode}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('pincode',edit.pincode)}   className='chip_evd' label={active==='pincode'&&processing?'Saving':active==='pincode'&&!processing? 'Save':'Edit Pincode'} />
{  active==='pincode'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='map_url'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.map_url}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('map_url',edit.map_url)}   className='chip_evd' label={active==='map_url'&&processing?'Saving':active==='map_url'&&!processing? 'Save':'Edit Map Url'} />
{  active==='map_url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {!editor?<Typography style={{    maxHeight: "350px",
    overflow: 'scroll',
    textAlign: 'justify'
}} dangerouslySetInnerHTML={{__html: edit.overview}} className='value_evd'>
  
     </Typography>:<MyEditorS quillChange={quillChange} editor={editor}  />}

     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>Editor(edit.overview)} label={editor&&processing?'Saving':editor&&!processing?'Save':'Edit Overview'} />
     {  editor?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {active===`offer`? 
     <Grid container>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.offer_name} label='Offer' placeholder='enter offer name' onChange={(e)=>setData({...data,offer_name:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.offer_heading} label='Offer Heading' placeholder='enter offer heading' onChange={(e)=>setData({...data,offer_heading:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.offer_description} label='Offer Description' multiline minRows={1} placeholder='enter offer description' onChange={(e)=>setData({...data,offer_description:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Offer Expiry Date"
        value={data.expiry_date?data.expiry_date:new Date()}
        onChange={(newValue) => {
          setData({...data,expiry_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider></Grid>

    <Grid  style={{padding:'3vh', textAlign:'center'}}item sm={6} xs={12}>
    <FormControl>
      <FormLabel className='text1_evd' id="demo-row-radio-buttons-group-label">Coupon Redeem</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group" value={redeem} onChange={(e)=>Redeem(e.target.value)}
      >
        <FormControlLabel value="Coupon Code" control={<Radio style={{color:'var(--pinkColor)'}} />} label="Coupon Code" />
        <FormControlLabel value="External Link" control={<Radio style={{color:'var(--pinkColor)'}} />} label="External Link" />
       
      </RadioGroup>
    </FormControl></Grid>
    <Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={redeem==='Coupon Code'?data.coupon:data.url} label={`${redeem} Value`} multiline minRows={1} placeholder={`Enter ${redeem} Value`} onChange={(e)=>DataR(e.target.value)} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
            <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(data.checkedS)}  onChange={(e)=>changeSwitch(`offer`,e.target.checked)} />} label="Active" />
    </FormGroup></Grid>

     </Grid>:
     <Typography className='text1_evd'>
  Entity Offers
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditOffer(`offer`,'')}   className='chip_evd' label={active===`offer`&&processing?'Saving':active===`offer`&&!processing? 'Save':`Add New Offer`} />
  {  active===`offer`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
  
    
     </Grid>
     </Grid>
      {edit.offers&&edit.offers.map((val,idx)=>
       <Grid key={idx} container style={{paddingTop:'3vh',alignItems:'center'}} sm={12} xs={12}>
       <Grid item sm={8} xs={8}>
    {active===`offer${idx}`? <Grid container>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.offer_name} label='Offer' placeholder='enter offer name' onChange={(e)=>setData({...data,offer_name:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.offer_heading} label='Offer Heading' placeholder='enter offer heading' onChange={(e)=>setData({...data,offer_heading:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.offer_description} label='Offer Description' multiline minRows={1} placeholder='enter offer description' onChange={(e)=>setData({...data,offer_description:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Offer Expiry Date"
        value={data.expiry_date?data.expiry_date:new Date(val.offer_expiry)}
        onChange={(newValue) => {
          setData({...data,expiry_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider></Grid>
    <Grid  style={{padding:'3vh', textAlign:'center'}}item sm={6} xs={12}>
    <FormControl>
      <FormLabel className='text1_evd' id="demo-row-radio-buttons-group-label">Coupon Redeem</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group" value={redeem} onChange={(e)=>Redeem(e.target.value)}
      >
        <FormControlLabel value="Coupon Code" control={<Radio style={{color:'var(--pinkColor)'}} />} label="Coupon Code" />
        <FormControlLabel value="External Link" control={<Radio style={{color:'var(--pinkColor)'}} />} label="External Link" />
       
      </RadioGroup>
    </FormControl></Grid>
    <Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={redeem==='Coupon Code'?data.coupon:data.url} label={`${redeem} Value`} multiline minRows={1} placeholder={`Enter ${redeem} Value`} onChange={(e)=>DataR(e.target.value)} className='txt_field_evd' />
</Grid>

<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
            <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active===`offer${idx}`?data.checkedS:val.is_active)}  onChange={(e)=>changeSwitch(`offer${idx}`,e.target.checked)} />} label="Active" />
    </FormGroup></Grid>
     </Grid> : <Grid container>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
     <b>Offer Name</b> : {val.offer_name}
    
       </Typography></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
<b>Offer Heading :</b> {val.offer_heading}
    
       </Typography></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
<b>Offer Expiry :</b> {val.offer_expiry}
    
       </Typography></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
<b>Offer Description :</b> {val.offer_description}
    
       </Typography></Grid>
       <Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
{val.coupon_redeem?<Typography className='value_evd'>
<b>{val.coupon_redeem +' :'} </b> {val.coupon_redeem==='Coupon Code'?val.coupon:val.url}
    
       </Typography>:null}</Grid>

       <Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>

<Typography className='value_evd'>
<b>Is Active</b> : {val.is_active ?<i  className="fas fa-check-circle icon_review"></i>:<i  className="fas fa-times-circle icon_review"></i>}


</Typography>
</Grid>

     </Grid>   }
       </Grid> 
       <Grid item style={{textAlign:'start',alignItems:'center'}} sm={4} xs={4}>
       <Chip  onClick={()=>EditOffer(`offer${idx}`,val)}   className='chip_evd' label={active===`offer${idx}`&&processing?'Saving':active===`offer${idx}`&&!processing? 'Save':`Edit Offer${idx+1}`} />
  {  active===`offer${idx}`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
       </Grid>
       </Grid>  ) }

       <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {!editor1?<Typography style={{    maxHeight: "350px",
    overflow: 'scroll',
    textAlign: 'justify'
}} dangerouslySetInnerHTML={{__html: edit.blog}} className='value_evd'>
  
     </Typography>:<MyEditorS2 quillChange1={quillChange1} editor1={editor1}  />}

     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>Editor1(edit.blog)} label={editor1&&processing?'Saving':editor1&&!processing?'Save':'Edit Blog'} />
     {  editor1?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
    
     </Grid>
     </Grid> 



     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {active===`review`? 
     <Grid container>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.fname} label='First Name'  onChange={(e)=>setData({...data,fname:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.lname} label='Last Name'  onChange={(e)=>setData({...data,lname:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.review} label='Review'  onChange={(e)=>setData({...data,review:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.review_detail} label='Review Detail' multiline minRos={1}  onChange={(e)=>setData({...data,review_detail:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<div  style={{display:'flex',justifyContent:'center',paddingTop:'2vh'}} >
            <i onClick={()=>setRatingE(1)} className={ratingE>0?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(2)} className={ratingE>1?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(3)} className={ratingE>2?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(4)} className={ratingE>3?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(5)}  className={ratingE>4?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            </div></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
</Grid>

     </Grid>:
     <Typography className='text1_evd'>
  Entity Reviews
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditReview(`review`,'')}   className='chip_evd' label={active===`review`&&processing?'Saving':active===`review`&&!processing? 'Save':`Add New Review`} />
  {  active===`review`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
  
    
     </Grid>
     </Grid>
     {edit.reviews&&edit.reviews.map((val,idx)=>
       <Grid key={idx} container style={{paddingTop:'3vh'}} sm={12} xs={12}>
       <Grid item sm={8} xs={8}>
    {active===`review${idx}`? <Grid container>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.fname} label='First Name'  onChange={(e)=>setData({...data,fname:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.lname} label='Last Name'  onChange={(e)=>setData({...data,lname:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.review} label='Review'  onChange={(e)=>setData({...data,review:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<TextField value={data.review_detail} label='Review Detail' multiline minRos={1}  onChange={(e)=>setData({...data,review_detail:e.target.value})} className='txt_field_evd' />
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<div  style={{display:'flex',justifyContent:'center',paddingTop:'2vh'}} >
            <i onClick={()=>setRatingE(1)} className={ratingE>0?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(2)} className={ratingE>1?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(3)} className={ratingE>2?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(4)} className={ratingE>3?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(5)}  className={ratingE>4?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            </div></Grid>

            <Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
            <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active===`review${idx}`?data.checkedS:val.is_verified)}  onChange={(e)=>changeSwitch(`review${idx}`,e.target.checked)} />} label="Verified" />
    </FormGroup></Grid>

     </Grid> : <Grid container>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
     <b>First Name</b> : {val.user_fname}
    
       </Typography></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
<b>Last Name</b> : {val.user_lname}
    
       </Typography></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
<b>Review </b> : {val.review}
    
       </Typography></Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>
<Typography className='value_evd'>
<b>Review Detail</b> : {val.review_detail}
    
       </Typography></Grid>
       <Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>

       <Typography className='value_evd'>
<b>Ratings</b> : {val.ratings}  <i  className="fas fa-star icon_review"></i>

    
       </Typography>
</Grid>
<Grid  style={{paddingTop:'3vh'}}item sm={6} xs={12}>

<Typography className='value_evd'>
<b>Is verified</b> : {val.is_verified ?<i  className="fas fa-check-circle icon_review"></i>:<i  className="fas fa-times-circle icon_review"></i>}


</Typography>
</Grid>
     </Grid>   }
       </Grid> 
       <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
       <Chip  onClick={()=>EditReview(`review${idx}`,val)}   className='chip_evd' label={active===`review${idx}`&&processing?'Saving':active===`review${idx}`&&!processing? 'Save':`Edit Review${idx+1}`} />
  {  active===`review${idx}`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
       </Grid>
       </Grid>  ) }
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {active===`tag`? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:
     <Typography className='text1_evd'>
  Entity Tags
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT(`tag`,'')}   className='chip_evd' label={active===`tag`&&processing?'Saving':active===`tag`&&!processing? 'Save':`Add New Tag`} />
  {  active===`tag`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
  
    
     </Grid>
     </Grid>





    

      {edit.tags.map((val,idx)=>
       <Grid key={idx} container style={{paddingTop:'3vh'}} sm={12} xs={12}>
       <Grid item sm={8} xs={8}>
    {active===`tag${idx}`? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
     {val.tag}
    
       </Typography>}
       </Grid> 
       <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
       <Chip  onClick={()=>EditT(`tag${idx}`,val.tag,val.id)}   className='chip_evd' label={active===`tag${idx}`&&processing?'Saving':active===`tag${idx}`&&!processing? 'Save':`Edit Tag${idx+1}`} />
  {  active===`tag${idx}`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
       </Grid>
       </Grid>  ) }
  
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {active===`social`?<>
<Grid itemsm={12} xs={12}>
     <Chip

label={!activeVal1?'':activeVal1}
onClick={()=>setActive1(!active1)}
onDelete={()=>setActive1(!active1)}
deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/></Grid>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
<div className="filter_values">
<ul>
{Events_Platform.map((val,idx)=>
<li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
{val}
</li>) 
}


</ul>
</div>

</div>:null}
     <TextField value={text1} onChange={(e)=>setText1(e.target.value)} label='Social Media Url' className='txt_field_evd' style={{marginTop:'2vh'}} />

     </> :
     <Typography className='text1_evd'>
   Entity Social Media Platforms
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center'}} xs={4}>
     <Chip  onClick={()=>EditT(`social`,'')}   className='chip_evd' label={active===`social`&&processing?'Saving':active===`social`&&!processing? 'Save':`Add New Platform`} />
  {  active===`social`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
  
    
     </Grid>
     </Grid>
    {edit.social_media.map((val,idx)=>
    <Grid container key={idx} style={{paddingTop:'3vh'}} sm={12} xs={12}>
      <Grid item sm={12} xs={12}>
        <Typography className='text1_evd'>{val.platform}</Typography>
        </Grid>
    <Grid item style={{marginBottom:'2vh'}} sm={8} xs={8}>
    {active===`social${idx}`&&activeS==='platform'? 
    <>
    <Grid  item sm={12} xs={12}>
     <Chip

label={!activeVal1?val.platform:activeVal1}
onClick={()=>setActive1(!active1)}
onDelete={()=>setActive1(!active1)}
deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/></Grid>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
<div className="filter_values">
<ul>
{Events_Platform.map((val,idx)=>
<li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
{val}
</li>) 
}


</ul>
</div>

</div>:null}
    </>
    :
    <Typography className='value_evd'>
  {val.platform}
 
    </Typography>}
    </Grid> 
    <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
    <Chip  onClick={()=>EditS(`social${idx}`,'platform',val.platform,val.id,val.url)}   className='chip_evd' label={active===`social${idx}`&&activeS==='platform' &&processing?'Saving':active===`social${idx}`&&activeS==='platform' &&!processing? 'Save':`Edit Platform${idx+1}`} />
{  active===`social${idx}`&&activeS==='platform'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null} 
   
    </Grid>
    <Grid item sm={8} xs={8}>
    {active===`social${idx}`&&activeS==='url'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:

    <Typography className='value_evd'>
  {val.url}
 
    </Typography>}
    </Grid> 
    <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
    <Chip  onClick={()=>EditS(`social${idx}`,'url',val.url,val.id,val.platform)}   className='chip_evd' label={active===`social${idx}`&&activeS==='url'&&processing?'Saving':active===`social${idx}`&&activeS==='url'&&!processing? 'Save' :`Edit ${val.platform} Url`} />
{  active===`social${idx}`&&activeS==='url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null} 
   
    </Grid>
    </Grid> 
    
    
    
    
    ) }

    
    <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='meta_title'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.meta_title}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('meta_title',edit.meta_title)}   className='chip_evd' label={active==='meta_title'&&processing?'Saving':active==='meta_title'&&!processing? 'Save':'Edit Meta Title'} />
{  active==='meta_title'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='meta_description'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.meta_description}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('meta_description',edit.meta_description)}   className='chip_evd' label={active==='meta_description'&&processing?'Saving':active==='meta_description'&&!processing? 'Save':'Edit Meta Decription'} />
{  active==='meta_description'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='meta_keywords'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.meta_keywords}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('meta_keywords',edit.meta_keywords)}   className='chip_evd' label={active==='meta_keywords'&&processing?'Saving':active==='meta_keywords'&&!processing? 'Save':'Edit Meta Keywords'} />
{  active==='meta_keywords'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='explore_url'? <TextField value={text} onChange={(e)=>setText(e.target.value.replace(' ','_'))} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.explore_url}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('explore_url',edit.explore_url)}   className='chip_evd' label={active==='explore_url'&&processing?'Saving':active==='explore_url'&&!processing? 'Save':'Edit Entity Url'} />
{  active==='explore_url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 


     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='admin_likes'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.admin_likes}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('admin_likes',edit.admin_likes)}   className='chip_evd'  label={active==='admin_likes'&&processing?'Saving':active==='admin_likes'&&!processing? 'Save':'Edit Admin Likes'} />
{  active==='admin_likes'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active==='show_likes_admin'?data.checkedS:edit.show_likes_admin)}  onChange={(e)=>changeSwitch('show_likes_admin',e.target.checked)} />} label="Show Admin Likes" />
    </FormGroup>
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('show_likes_admin',edit.show_likes_admin)}   className='chip_evd' label={active==='show_likes_admin'&&processing?'Saving':active==='show_likes_admin'&&!processing? 'Save':'Edit Show Admin Likes '} />
{  active==='show_likes_admin'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

       </Grid>:<Grid container style={{minHeight:'80vh'}}>

    <Grid container style={{alignItems:'center',marginBottom:'5vh'}} >
<Grid item sm={9} xs={8}>
<TextField placeholder='Search entities here ..' className='field_evd' />
</Grid>
<Grid item sm={3} xs={4}>
<Button onClick={()=>setNewE(true)} variant='contained' className='btn_evd'>Create New Entity</Button>
</Grid>

    </Grid>


    <Grid container>
{explore&&explore[0]&&!processing?<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:!explore[0]&&!processing?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Entities Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>
    </Grid>}</div>
  )
}

export default ExploreDash