import React from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import {useCookies} from 'react-cookie'

function Navbar() {
  const[auth,setAuth]=useCookies(['auth-happ'])


        return(
            <>
            <div className='D6_SL_Navbar_Items1'>
<Grid justifyContent={'center'} alignContent={'center'} container  className='gird_nav'>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="/" onClick={()=>window.location.href='/'}   >
                              EVENTS
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="explore"   >
                              XPLOR
                            </NavLink>
</Grid>


{/* <Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="help"   >
HELP
                            </NavLink>
</Grid> */}



<Grid item>
{auth&&auth['auth-happ']?<NavLink className={"D6_SL_Navbar_Link1"}  to="dashboard"   >
<i style={{fontSize:25}} class="fas fa-user-circle"></i>                           </NavLink>:<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="signin"   >
SIGN IN
                            </NavLink>}

</Grid>



    </Grid>                  
            </div>








            </>
        );
    }
    
    export default Navbar;