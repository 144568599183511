import React from 'react';

import Grid from '@mui/material/Grid';
import './header.css'
import {useCookies} from 'react-cookie'

function NavbarB() {
  const[auth,setAuth]=useCookies(['auth-happ'])

        return(
            <>
            <div className='D6_SL_Navbar_Items1'>
<Grid justifyContent={'center'} alignContent={'center'} container  className='gird_nav'>
<Grid item>
<div className={ "D6_SL_Navbar_Link1"}   onClick={()=>window.location.href='/'}   >
                              EVENTS
                            </div>
</Grid>
<Grid item>
<div className={ "D6_SL_Navbar_Link1"} onClick={()=>window.location.href='/explore'}    >
                              XPLOR
                            </div>
</Grid>


{/* <Grid item>
<div className={ "D6_SL_Navbar_Link1"}     >
HELP
                            </div>
</Grid> */}



<Grid item>
{auth&&auth['auth-happ']?<i  onClick={()=>window.location.href='/dashboard'}  style={{fontSize:25,cursor:'pointer',paddingLeft:10}} class="fas fa-user-circle"></i>  :
<div className={ "D6_SL_Navbar_Link1"} onClick={()=>window.location.href='/signin'}    >
SIGN IN
                            </div>}

</Grid>



    </Grid>                  
            </div>








            </>
        );
    }
    
    export default NavbarB;