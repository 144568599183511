import { Avatar, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Container, Dialog, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CategoriesMain, ExploreDataparticular, SearchCategories } from '../homepage/homeData'
import '../event/particularEvent.css'
import './exploreParticular.css'
import { ReactPhotoCollage } from "react-photo-collage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { img_link, live } from '../../App'
import {useCookies} from 'react-cookie'
import SignIND from '../auth/signinD'
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment'

const NextArrow = ({ onClick }) => {
    return (
      <div className="nextArrow" onClick={onClick}>
        <BsChevronRight />
      </div>
    );
  };
  
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="prevArrow" onClick={onClick}>
        <BsChevronLeft />
      </div>
    );
  };
export default function ParticularExplore(props) {
    const explore_url=props.page2

const[ratingE,setRatingE]=useState(null)
const [activeE,setActiveE]=useState('Overview')
const[processing,setProcessing]=useState(false)
const [event,setEvent]=useState('')
const [exploreR,setExploreR]=useState('')

const [openD,setOpenD]=useState(false)
const[reload,setReload]=useState(0)
const[auth]=useCookies(['auth-happ'])
const [liked,setLiked]=useState(false)
const [error,setError]=useState('')
const[processing1,setProcessing1]=useState(false)
const [data,setData]=useState({})
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)

useEffect(() => {
    setProcessing(true)
 fetch(live+`explore-url?q1=${explore_url}`).then((res)=>res.json()).then(resp=>eventRes(resp))
},[])


useEffect(() => {
  if(event&&event[0]){
    // setProcessing(true)
    fetch(live+`share?category=${event[0].category}&state=${event[0].stat}&city=${event[0].city}&type=explore`).then((res)=>res.json()).then(resp=>setExploreR(resp.data))
   
  }
   },[event])
useEffect(() => {

  if(reload>0){
fetch(live+`explore-url?q1=${explore_url}`).then((res)=>res.json()).then(resp=>eventRes(resp))
}},[reload])
const eventRes=(val)=>{
   
  if(val.data){
    setProcessing(false)
    setLiked(false)
    setEvent(val.data)
  }
    else{
      window.location.href='/'
    }
}
const handleClose=()=>{
  setOpenD(false)
  setOpen1(false)
  setOpen(false)
}
const Follow=()=>{
  if(auth&&auth['auth-happ']){
    setLiked(true)
    const formdata =new FormData()
    formdata.append('liked_by',auth['auth-happ'].user_id)
    formdata.append('explore',event[0].explore_id)
fetch(live+'explore-url',
{
  method:'PUT',
  body:formdata
}).then(resp=>resp.json()).then(res=>likeRes(res))
  }
  else{
    setOpenD(true)
  }
}


const likeRes=()=>{
  setReload(reload+1)
}

var settings = {
    dots: true,
    infinite: true,
  
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };
const settingI = {
    width: '100%',
    height: ['350px', '170px'],
    layout: [2, 4],
    // photos: [
    //   { source:require('../../images/Food.jpg')  },
     
    //   { source:require('../../images/EV_1.jpg')  },
    //   { source:require('../../images/EV_2.jpg')  },
     
    //   { source:require('../../images/EV_3.jpg')  }, 
    //   { source:require('../../images/Art.jpg')  },
     
    //   { source:require('../../images/Business.jpg')  },


    
    // ]
    photos:event&&event[0].images?(event[0].images.filter(val=>val.is_active==true).map((item,k) => {
        return  {
          'source':img_link+item.image
      
      
         
        }})):[],
    
    showNumOfRemainingPhotos: true
  };




  const submitReview=()=>{

if(auth&&auth['auth-happ']){
  setProcessing1(true)
  const formdata=new FormData()
  formdata.append(`active`,'review') 
  formdata.append('user_fname',auth['auth-happ'].fname)
  formdata.append('user_lname',auth['auth-happ'].lname)
  formdata.append('review',data.review)
  formdata.append('review_detail',data.review_detail)
  formdata.append('ratings',ratingE)
  formdata.append('val','0')

  formdata.append('user_id',auth['auth-happ'].user_id)


  formdata.append('explore_id',event[0].explore_id) 
  formdata.append('sub_id','') 




fetch(live+'explore',
    {
      method:'PUT',
      body:formdata
    }).then(res=>res.json()).then((resp)=>editRes1(resp))


}
else{
  setOpenD(true)
}


  }  

  const editRes1=(resp)=>{
    if(resp.status){
      setError('Review submitted successsfullly, once verified review will be posted')
      setOpen1(true)
      setProcessing1(false)
    }
    else{
      setError('Error in submitting review, please try again later')
      setOpen(true)
      setProcessing1(false)
    }

  }


  const redeemOffer=(val)=>{

    if(val.coupon_redeem==='Coupon Code'){
    const el = document.createElement('textarea');
      el.value =val.coupon;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    setError('Coupon Copied')
    setOpen1(true)
    }
    else{
      window.open(val.url,'_blank')
    }
  }



  const sendMail=()=>{
    if(auth&&auth['auth-happ']){
      setProcessing1(true)
          const formdata=new FormData
          formdata.append('user_name',auth['auth-happ'].fname)

          formdata.append('name',event[0].explore_name)
          formdata.append('value',`https://happ360.com/explore/${event[0].explore_url}`)
          formdata.append('email',auth['auth-happ'].email)
      
      
      fetch(live+'share',
      {
        method:'post',
        body:formdata
      }).then(res=>res.json()).then(resp=>mailRes(resp))
        }
        else{
          setOpenD(true)
        }
      }
      const mailRes=(val)=>{
        if(val.status){
          setError(val.detail)
          setOpen1(true)
          setProcessing1(false)
      
      
        }
        else{
          setProcessing1(false)
        }
      }


      const copyUrl=()=>{
        const el = document.createElement('textarea');
        el.value =`https://happ360.com/explore/${event[0].explore_url}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      setError('Event url Copied')
      setOpen1(true)
      }
  if(event&&!processing){
  return (
    <div>
{ event.map((val,idx)=>
<div key={idx}>   
<Helmet>
        {/* <!-- HTML Meta Tags --> */}

    <title>{val.meta_title}</title>
       
      
        <meta
          name="description"
          content={val.meta_description}
        />

    <meta name="keywords" content={val.meta_keywords} />
    

        {/* <!-- Google / Search Engine Tags --> */}
       
     
        <meta itemprop="name" content={val.meta_title}
         />
        
        
      
    <meta
    itemprop="description"
    content={val.meta_description}
  />
       
        
    

        <meta property="og:type" content="website" />
   
        <meta property="og:title" content={val.meta_title} />
        
      
    <meta
          property="og:description"
          content={val.meta_description}
        />
        
      
      

      
       
      </Helmet>
     <div style={{background:'#eee'}}>
     <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
     <Dialog
      className='dialog_signin'
      disableScrollLock={ true }
      open={openD}
      style={{textAlign:'center',margin:10}}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<SignIND handleClose={handleClose} />

    </Dialog>
    <Container style={{maxWidth:'100%',padding:0}}>

    <div className="explore_crousel">
    <Slider {...settings}    easing='linear' lazyLoad fade  >
       {val.images.filter(val=>val.is_active==true).map((val,idx)=>
     <Grid container className="explore_crousel_div" >
     <Grid item  sm={12} xs={12}>
   
 <img src={img_link+val.image} className='img_bg' />

     <img src={img_link+val.image} style={{maxWidth:'1200px'}} className='img_event' />
   

     </Grid>
 </Grid>  ) }
     
      

      </Slider>
      </div>
    </Container>
    </div>

    <Container style={{marginTop:'4vh',maxWidth:1300}} >
        <Grid container>
        <Grid item sm={12} xs={12}>
              <ul className="ul_navigation">

                <li>Home</li>
                <span><i class="fas fa-angle-right"></i></span>
                <li>Explore</li>
                <span><i class="fas fa-angle-right"></i></span>
                <li>{val.category}</li>
                <span><i class="fas fa-angle-right"></i></span>

                <li><b>{val.explore_name}</b></li>

                </ul>  
            </Grid>
       
    <Grid container style={{alignSelf:'start',maxWidth:'90%',paddingRight:20}} sm={8} xs={12}>
        
            <Grid  alignItems={'center'}  container style={{
              
            }} sm={12} xs={12}>

<Grid item style={{
                textAlign:'justify',
             
            }} sm={10} xs={12}>
<Typography style={{
                textAlign:'justify',
                paddingTop:0
            }} className='eventP_head'>
{val.explore_name}</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={2} xs={12}>


{(val.likes!==null&&val.likes.length>0&&auth['auth-happ']&&val.likes.find(val=>val.user_id===auth['auth-happ'].user_id))||liked?<Button  variant='contained' className='btn_eventP1'>Following</Button>:<Button onClick={()=>Follow()} variant='outlined' className='btn_explore'>Follow <i class="fas fa-plus-circle icon_explore"></i></Button>
}
</Grid>
</Grid>
<Typography className='typo_eventFollow'>
         <span><i class="far fa-user icon_user"></i></span> {val.show_likes_admin&&liked?val.admin_likes+1:val.show_likes_admin&&!liked?val.admin_likes:!val.show_likes_admin&&liked?val.likes.length+1:(val.likes!==null&&val.likes.length?val.likes.length:0)} followers
          </Typography>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_text'>
{val.addressLine1+', '+val.city+', '+val.stat+', '+val.pincode}</Typography>

{/* <Typography className='eventP_text'>
Time: 12:00 PM to 8:00 PM <span style={{color:'var(--pinkColor)',fontWeight:'500'}}>(Open Now)</span></Typography> */}
<Grid container style={{alignSelf:'start',padding:'10px 0px '}} sm={11} xs={11}>
<Grid item sm={12} xs={12}>
<Typography className='W_W_head'>
Valid Upto
</Typography>
</Grid>
<Grid item sm={12} xs={12}>
<Typography className='W_W_text'>

{moment(val.start_date).format("MMMM Do YYYY")+', '+val.start_time.slice(0,5).toString()+' - '+moment(val.end_date).format("MMMM Do YYYY")+', '+val.end_time.slice(0,5).toString()}

{/* Tue, Dec 6, 2022, 10:00 AM – Wed, Dec 7, 2022, 5:00 PM IST */}
</Typography>
</Grid>
</Grid>
</Grid>
<Grid item style={{
                display:'flex',marginTop:'2vh'
            }} sm={12} xs={12}>

<Button variant='outlined' onClick={()=>window.open(val.map_url,'_blank')} className='btn_explore'>Directions<i class="fas fa-route icon_explore"></i></Button>
{/* <Button variant='outlined' className='btn_explore'>Bookmarks<i class="fas fa-bookmark icon_explore"></i></Button> */}
<Button variant='outlined' onClick={()=>copyUrl()} className='btn_explore'>Copy Url <i class="fas fa-copy icon_explore"></i></Button>

</Grid>

<Grid container style={{ borderBottom:'1px solid lightgrey',
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className={activeE==='Overview'?'exploreP_DT_headA':'exploreP_DT_head'} onClick={()=>setActiveE('Overview')}>
Overview
</Typography>
{val.offers!=null&&val.offers.filter(val=>val.is_active===true).length>0?<Typography  className={activeE==='Offers'?'exploreP_DT_headA':'exploreP_DT_head'} onClick={()=>setActiveE('Offers')}>
Offers
</Typography>:null}
<Typography  className={activeE==='Reviews'?'exploreP_DT_headA':'exploreP_DT_head'} onClick={()=>setActiveE('Reviews')}>
Reviews
</Typography>
<Typography  className={activeE==='Photos'?'exploreP_DT_headA':'exploreP_DT_head'} onClick={()=>setActiveE('Photos')}>
Photos
</Typography>
<Typography  className={activeE==='Blog'?'exploreP_DT_headA':'exploreP_DT_head'} onClick={()=>setActiveE('Blog')}>
Blog
</Typography>
</Grid>

<Grid container  sm={12} xs={12}>
        


<Grid container style={{maxHeight:500,overflowY:'scroll'}}>
{activeE==='Overview'?<Grid container >
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

Overview
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography dangerouslySetInnerHTML={{__html: val.overview}}  className='eventP_Detail'>

</Typography>
</Grid>
</Grid>:
activeE==='Blog'?<Grid container >
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

Blog
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography dangerouslySetInnerHTML={{__html: val.blog}}  className='eventP_Detail'>

</Typography>
</Grid>
</Grid>:
activeE==='Offers'?
<Grid container >
    
{val.offers!=null&&val.offers.filter(val=>val.is_active===true).map((val,idx)=>
  <Paper key={idx} elevation={4} className='ppr_offer'>
  <Grid container>

      <Grid container style={{minHeight:'130px',maxWidth:'65px',position:'relative'}} sm={2} xs={2}>
       <div className='offer_strip'>
          
          <span>
{val.offer_name}
          </span>
          </div>   
      </Grid>
      <Grid style={{padding:'10px'}} container sm={7} xs={10}>
    <Grid item sm={12} xs={12}>
<Typography className='offer_head'> 
{val.offer_heading}
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <Typography className='offer_date'> 
Expiry Date: {moment(val.expiry_date).format("MMMM Do YYYY")}
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <Typography className='offer_text'> 
{val.offer_description}</Typography>
    </Grid>
      </Grid>
      <Grid container style={{marginTop:'20px',alignSelf:'start',textAlign:'end'}} sm={3} xs={12}>
          <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
              <Button variant='outlined' onClick={()=>redeemOffer(val)} className='btn_offers'>Redeem offer</Button>
          </Grid>
          {/* <Grid style={{paddingTop:'1vh'}} item sm={12} xs={12}>
         
          <i class="fab fa-facebook icon_share"></i>
<i class="fab fa-instagram icon_share"></i>
<i class="fab fa-whatsapp icon_share"></i>
          </Grid> */}
          </Grid>


  </Grid>

</Paper>
)  }
    </Grid>:activeE==='Photos'?<Grid item textAlign={'center'}  sm={12} xs={12}>

<ReactPhotoCollage  {...settingI}  />
</Grid>: <Grid container>
        
        <Grid item sm ={12} xs={12}>
            <Paper>
            <Grid item sm ={12} xs={12}>
            <Typography className='review_text'> 
<b>Rate This Place</b>
</Typography>
</Grid>
<Grid container style={{padding:'2vh 0px'}}>
<Grid item sm ={6} xs={6}>
<Grid item  sm ={12} xs={12}>
    <TextField  onChange={(e)=>setData({...data,review:e.target.value})} style={{paddingBottom:'1vh',width:'90%'}} placeholder='Enter your Review here..' />
</Grid>
<Grid item sm ={12} xs={12}>
    <TextField  style={{paddingBottom:'1vh',width:'90%'}} onChange={(e)=>setData({...data,review_detail:e.target.value})} placeholder='Review Description' />
</Grid>

</Grid>
<Grid item sm ={6} xs={6}>
            <div  style={{display:'flex',justifyContent:'center',paddingTop:'2vh'}} >
            <i onClick={()=>setRatingE(1)} className={ratingE>0?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(2)} className={ratingE>1?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(3)} className={ratingE>2?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(4)} className={ratingE>3?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i onClick={()=>setRatingE(5)}  className={ratingE>4?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            </div>
            <Grid item sm ={12} xs={12}>
    <Button  className='btn_review' disabled={processing1} onClick={submitReview} variant='contained'>{processing1?'Submitting':'Submit'}</Button>
</Grid>
</Grid>

</Grid>
<Grid container style={{padding:'2vh 10px'}}>

<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
{val.reviews&&val.reviews.filter(val=>val.is_verified===true).length>0?<Typography  className='eventP_DT_head'>

Customers Ratings
</Typography>:null}

</Grid>
{val.reviews&&val.reviews.filter(val=>val.is_verified===true).map((val,idx)=>
    <Grid container style={{padding:'2vh 0px'}}>
<Grid item sm={1} xs={1}>
<Avatar>{val.user_fname.charAt(0).toUpperCase()}{val.user_lname.charAt(0).toUpperCase()}</Avatar>

</Grid>
<Grid container sm={11} xs={11}>
<Grid item sm={12} xs={12}>
<Typography className='offer_head'> 
{val.user_fname+' '+val.user_lname}
</Typography>
    </Grid>
<Grid item sm={12} xs={12}>

<Typography className='offer_head'> 
{val.ratings} <span >  <i  className={val.ratings>0?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i  className={val.ratings>1?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i  className={val.ratings>2?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i  className={val.ratings>3?"fas fa-star icon_review":"far fa-star icon_review"}></i>
            <i   className={val.ratings>4?"fas fa-star icon_review":"far fa-star icon_review"}></i>
          </span>
</Typography>
    </Grid>
   
<Grid item sm={12} xs={12}>
<Typography  className='offer_head'> 
{val.review}
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
<Typography  style={{textAlign:'justify'}} className='eventP_Detail'> 
{val.review_detail}  
</Typography>  </Grid>

</Grid>

</Grid>
)}
</Grid>


            </Paper>
            </Grid>
        
        </Grid>}
    </Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

Tags
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify',marginTop:'2vh',marginBottom:'2vh'
            }} sm={12} xs={12}>
{val.tags.map((val,idx)=>
    <Chip label={val.tag} key={idx}  className='chip_tags'/>)}
</Grid>
{val.social_media?<><Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

Share with friends
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify',paddingTop:'2vh',paddingBottom:'3vh'
            }} sm={12} xs={12}>
{val.social_media.find(val=>val.platform.includes('Facebook'))?<i class="fab fa-facebook icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Facebook')).url,'_blank')}></i>:null}
{val.social_media.find(val=>val.platform.includes('Linked In'))?<i class="fab fa-linkedin icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Linked In')).url,'_blank')}></i>:null}
{val.social_media.find(val=>val.platform.includes('Whatsapp'))?<i class="fab fa-whatsapp icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Whatsapp')).url,'_blank')}></i>:null}

{val.social_media.find(val=>val.platform.includes('Instagram'))?<i class="fab fa-instagram icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Instagram')).url,'_blank')}></i>:null}

{val.social_media.find(val=>val.platform.includes('Snapchat'))?<i class="fab fa-snapchat-ghost icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Snapchat')).url,'_blank')}></i>:null}


</Grid></>:null}
            </Grid>
        </Grid>
        <Grid container alignContent={'flex-start'}  sm={4} xs={12}>
<Grid container  justifyContent={'center'} sm={12} xs={12}>
<Paper elevation={3} className='ppr_sales'>
    <Grid item sm={12} xs={12}>
        <Typography className='text_sale'>
{event[0].explore_type}
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Button variant='contained'onClick={()=>sendMail()} className='btn_sales'>
        {processing1? 'Sending': 'Share Via E-Mail'}
        </Button>
    </Grid>

</Paper>
</Grid>
{/* <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
<div className='related_events'>
<Grid item sm={12} xs={12}>
<Typography className='related_events_head'>
You May Also Like
</Typography>

</Grid>
<Grid item sm={12} className='cont_relatedE' xs={12}>

{ExploreDataparticular.map((val,idx)=>
    <Card style={{marginTop:'1vh'}} key={idx} className='card_event' sx={{ maxWidth: '90%' }}>
      <CardMedia
        component="img"
        height="140"
        image={val.image}
        alt="Event"
      />
        <CardContent >
          
        <Typography className='typo_eventHead1'>
        {val.offer}
        </Typography>
       
          <Typography className='typo_eventAuth'>
          
          {val.name}
          </Typography>
          <Typography className='typo_eventAuth'>
          
         <Chip className='chip_p' label={val.category} />
          </Typography>
          <Typography className='typo_eventFollow'>
         <span><i class="far fa-user icon_user"></i></span> 6.2k followers
          </Typography>
        </CardContent>
      </Card>
)}
</Grid>

</div>

</Grid> */}

{exploreR&&exploreR[0]?<Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
<div className='related_events'>
<Grid item sm={12} xs={12}>
<Typography className='related_events_head'>
You May Also Like</Typography>

</Grid>
<Grid item sm={12} className='cont_relatedE' xs={12}>

{exploreR.map((val,idx)=>
    <Card style={{marginTop:'1vh'}} key={idx} className='card_event' sx={{ maxWidth: '90%' }}>
      <CardMedia
      // className='img_card'
        component="img"
        height="140"
        image={img_link+val.images[0].image}
        alt="Event"
      />
        <CardContent >
          
        <Typography className='typo_eventHead1'>
       {val.event_name}
        </Typography>
        <Typography className='typo_eventTime'>
        {moment(val.start_date).format("MMMM Do YYYY")+', '+val.start_time.slice(0,5).toString()+' - '+moment(val.end_date).format("MMMM Do YYYY")+', '+val.end_time.slice(0,5).toString()}
          </Typography>
       
          <Typography className='typo_eventAuth'>
          
          by {val.event_organiser}
          </Typography>
          <Typography className='typo_eventFollow'>
         <span><i class="far fa-user icon_user"></i></span> {val.show_likes_admin&&liked?val.admin_likes+1:val.show_likes_admin&&!liked?val.admin_likes:!val.show_likes_admin&&liked?val.likes.length+1:(val.likes!==null&&val.likes.length?val.likes.length:0)} followers
          </Typography>
        </CardContent>
      </Card>
)}
</Grid>

</div>

</Grid>:null}

        </Grid>
        </Grid>
    </Container>
        </div>)}
        </div>

  )}
  else{
    return(
      <Grid sm={12} style={{textAlign:'center',height:'100vh'}} xs={12} item>
      <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh',color:'var(--pinkColor)'}} />
      </Grid>)
  }
}
