import { Chip, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import './dashboard.css'
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css' 
import {useCookies} from 'react-cookie'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { live } from '../../App';
export default function Profile_Dash(props) {

 
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
    const [active,setActive]=useState('')

    const [verify,setVerify]=useState(false)
    const[auth,setAuth]=useCookies(['auth-happ'])
const [text,setText]=useState('')
const [otp,setOtp]=useState('')
const [code,setCode]=useState('')

const [errMsg,setMsg]=useState('')

const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [processing,setProcessing]=useState(false)

const handleClose=()=>{
    setOpen(false)
    setOpen1(false)
  
  }
const Save=()=>{
setProcessing(true)



const formData=new FormData
formData.append('user_id',auth['auth-happ'].user_id)

formData.append(`${active}`,text)


fetch(live+'auth',
{
  method:'PUT',
  body:formData
}
).then((res)=>res.json())
.then(resp=>profileRes(resp))



}

const profileRes=(resp)=>{
    if(resp.status&&resp.data){
        setMsg(resp.detail)
        setAuth('auth-happ',resp.data,{
            maxAge: 3600 ,
            expires:0,
            path:'/'
         })
         setOpen1(true)
         setProcessing(false)
         setActive(false)
         setVerify(false)
    }
    else{
        setMsg(resp.detail)
        setProcessing(false)
        setActive(false)
        setVerify(false)

         setOpen(true)
    }
}
// console.log(auth['auth-happ']['mobile'])
    const Active=(val)=>{
        if(val==='phone'||val==='email'){
if(active==='phone'||active==='email'){
    // setActive(val)
if(active==='email'&&val==='email'){
Verify()
}
if(active==='phone'&&val==='phone'){
  Verify()
  }
else{
    setVerify(true)
}

}
else{
    setActive(val)   
    setText(val==='phone'?(auth['auth-happ'].mobile):auth['auth-happ'][`${val}`])
}

          
        }
        else{
if(active===val){
    setVerify(false)

    Save()
}

            setActive(val)
            setText(auth['auth-happ'][`${val==='phone'?'mobile':val}`])

            setVerify(false)
        }
    }



    const Verify=()=>{
        setProcessing(true)
const formData=new FormData
formData.append('otpType',active)

formData.append('val',text)
formData.append('email',auth['auth-happ'].email)



fetch(live+'verify',
{
  method:'PUT',
  body:formData
}
).then((res)=>res.json())
.then(resp=>verifyRes(resp))


    }

    const verifyRes=(res)=>{
        if(res.status){
            setProcessing(false)
            setMsg(res.detail)
            setOpen1(true)
            setVerify(true)
        }
        else{
            setProcessing(false)
            setMsg(res.detail)
            setOpen(true)
            setVerify(false)  
        setActive('')      }
    }
    const Cancel=()=>{
        setActive('')
        setVerify(false)
    }
    const Phone=(val,val1)=>{
        setCode('+'+val.dialCode)
        setText(val1.replace('+'+val.dialCode+' ',''))
        // setData({...data,code:'+'+val.dialCode,mobile:val1.replace('+'+val.dialCode+' ','')})
        }


    const VerifyOTP=(val)=>{
        setProcessing(true)


     
      
            const formData=new FormData

            
            formData.append('loginType',active)
            
            formData.append(`${active=='phone'?'phonenumber':active}`,text)
            formData.append('otp',otp)
    
            
            fetch(live+'verify',
            {
              method:'POST',
              body:formData
            }
            ).then((res)=>res.json())
            .then(resp=>verifyOTPRes(resp))
        
        
        
    }    
    
    const verifyOTPRes=(res)=>{
        if(res.status){
           Save()
        }
        else{
            setProcessing(false)
            setMsg(res.detail)
            setOpen(true)
            setVerify(false)  
        setActive('')      }
    }







if(auth&&auth['auth-happ']){

  return (
    <Container style={{minHeight:'50vh'}}>
        <Grid container style={{paddingTop:'15vh'}}>
            <Grid item sm={12} xs={12}>
                <Typography className='profile_head'>
                  MY PROFILE 
                </Typography>
            </Grid>
            <Grid style={{paddingTop:'2vh',paddingBottom:'2vh'}} container sm={12} xs={12}>
            <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>
                <Grid container sm={7} xs={12}>
<Grid container sm={12} xs={12}>
   
   <Grid style={{marginTop:5,marginBottom:5}} container sm={12} xs={12}>
    <Grid item sm={6} xs={6}>
    {active==='fname'? 
     <TextField value={active==='fname'?text:auth['auth-happ'].fname}   onChange={(e)=>setText(
     e.target.value
    )} style={{width:'100%'}}placeholder="First Name" label='First Name' />
    
       : <Typography className='profile_typo'>
            <b>First Name :</b> {active==='fname'?text:auth['auth-happ'].fname}
        </Typography>}
        </Grid>
        <Grid item sm={6} xs={6}>

<Chip label={active==='fname'&&!processing?'Save':active==='fname'&&processing?'Saving':'Edit First Name'} onClick={()=>Active('fname')} className='chip_orderPay' />

{active==='fname'?<Chip label={'Cancel'} style={{marginLeft:'10px'}} onClick={()=>Cancel()} className='chip_orderPay' />:null}

</Grid>


    </Grid>
    <Grid style={{marginTop:5,marginBottom:5}} container sm={12} xs={12}>
    <Grid item sm={6} xs={6}>
    {active==='lname'?  <TextField  value={active==='lname'?text:auth['auth-happ'].lname}  onChange={(e)=>setText(
     e.target.value
    )} style={{width:'100%'}}placeholder="Last Name" label='Last Name' />
    
       : <Typography className='profile_typo'>
            <b>Last Name :</b> {active==='lname'?text:auth['auth-happ'].lname}
        </Typography>}
        </Grid>
        <Grid item sm={6} xs={6}>

<Chip label={active==='lname'&&!processing?'Save':active==='lname'&&processing?'Saving':'Edit Last Name'} onClick={()=>Active('lname')} className='chip_orderPay' />

{active==='lname'?<Chip label={'Cancel'} style={{marginLeft:'10px'}} onClick={()=>Cancel()} className='chip_orderPay' />:null}

</Grid>


    </Grid>


    <Grid style={{marginTop:5,marginBottom:5}} container sm={12} xs={12}>
    <Grid item sm={6} xs={6}>
    {active==='phone'?  
    <>
    
    {!verify&&active==='phone'
    ? 
    <TextField  value={active==='phone'?text:auth['auth-happ'].mobile}  onChange={(e)=>setText(
      e.target.value
     )} style={{width:'100%'}}placeholder="Enter Phone number" label='Phone' />
    :null} 

        {verify&&active==='phone'?
        <Grid style={{marginTop:5,marginBottom:5}} container sm={12} xs={12}>
        <Typography className='profile_typo'>
            <b>Phone :</b> {active==='phone'?text:auth['auth-happ'].country_code+' '+auth['auth-happ'].mobile}
        </Typography>
        
        <Grid item sm={6} xs={6}>
        <TextField    onChange={(e)=>setOtp(
         e.target.value
        )} style={{width:'100%'}}placeholder="Enter OTP" label='OTP' />
        
          
            </Grid>
            <Grid item sm={6} xs={6}>
    
    <Chip label={verify&&active==='phone'&&processing?'Processing':'Verify'} onClick={()=>VerifyOTP('phone')} className='chip_orderPay' />
    
    {<Chip label={'Cancel'} style={{marginLeft:'10px'}} onClick={()=>Cancel()} className='chip_orderPay' />}
    
    </Grid>
    
    
        </Grid>   

        :null}
        </>
       : <Typography className='profile_typo'>
            <b>Phone :</b> {active==='phone'?text:auth['auth-happ'].country_code+' '+auth['auth-happ'].mobile}
        </Typography>}
        </Grid>
{!verify&&active==='phone'||!verify&&active!=='phone'?        <Grid item sm={6} xs={6}>

<Chip label={active==='phone'&&!processing?'Send OTP':active==='phone'&&processing?'Sending':'Edit Phone'} onClick={()=>Active('phone')} className='chip_orderPay' />

{active==='phone'?<Chip label={'Cancel'} style={{marginLeft:'10px'}} onClick={()=>Cancel()} className='chip_orderPay' />:null}

</Grid>:null}


    </Grid>




    <Grid style={{marginTop:5,marginBottom:5}} container sm={12} xs={12}>
    <Grid item sm={6} xs={6}>
    {active==='email'?  
    <>
    
    {!verify&&active==='email'?
     <TextField    value={active==='email'?text:auth['auth-happ'].email} onChange={(e)=>setText(
        e.target.value
       )} style={{width:'100%'}}placeholder="Email" label='Email' />
       :null}

        {verify&&active==='email'?
        <Grid style={{marginTop:5,marginBottom:5}} container sm={12} xs={12}>
            <Typography className='profile_typo'>
            <b>Email :</b> {active==='email'?text:auth['auth-happ'].email}
        </Typography>
        <Grid item sm={6} xs={6}>
        <TextField    onChange={(e)=>setOtp(
         e.target.value
        )} style={{width:'100%'}}placeholder="Enter OTP" label='OTP' />
        
          
            </Grid>
            <Grid item sm={6} xs={6}>
    
    <Chip onClick={()=>VerifyOTP('email')} label={verify&&active==='email'&&processing?'Processing':'Verify'} className='chip_orderPay' />
    
    {<Chip label={'Cancel'} style={{marginLeft:'10px'}} onClick={()=>Cancel()} className='chip_orderPay' />}
    
    </Grid>
    
    
        </Grid>   

        :null}
        </>
       : <Typography className='profile_typo'>
            <b>Email :</b> {active==='email'?text:auth['auth-happ'].email}
        </Typography>}
        </Grid>
{!verify&&active==='email'||!verify&&active!=='email'?        <Grid item sm={6} xs={6}>

<Chip label={active==='email'&&!processing?'Send OTP':active==='email'&&processing?'Sending':'Edit Email'}    onClick={()=>Active('email')} className='chip_orderPay' />

{active==='email'?<Chip label={'Cancel'} style={{marginLeft:'10px'}} onClick={()=>Cancel()} className='chip_orderPay' />:null}

</Grid>:null}


    </Grid>
 
    
   

    
    

</Grid>
                </Grid>
          
            </Grid>
        </Grid>

     
    </Container>
  )
}
else{
    window.location.href='/signin'
}}
