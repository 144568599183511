import { Grid ,Container} from '@mui/material'
import React from 'react'
import './footer.css'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="Footer_Items_Block">
        <Container className="Footer_Items">
            
            <div className="Footer_Items_NavLink Footer_Items_NavLink1">
                <NavLink className="Footer_Link" exact to="/">
                        HOME
                </NavLink>
            </div>
            {/* <div className="Footer_Items_NavLink Footer_Items_NavLink2">
            <Link className="" href="https://www.dec6.in/AboutUs" target='_blank'>
                            About Us
                        </Link>
            </div> */}
            <div className="Footer_Items_NavLink Footer_Items_NavLink3">
                           <a className="" href="https://www.dec6.in/AboutUs" target='_blank'>
                            ABOUT US
                        </a>
            </div>
            <div className="Footer_Items_P_Div">  
                <p className="Footer_Items_P">
                    &#169;2023 Dec6 Consulting Private Limited

 
                </p>
            </div>
        </Container>
    </div>
    )
}

export default Footer
