import {  Card, CardMedia,Button, Chip, CircularProgress, Container, Grid, Paper, TextField, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './eventDash.css'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { img_link, live } from '../../App';
import { MyEditorS } from './editor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CategoriesMain, Events_Mode, Events_Platform, Events_Type } from '../homepage/homeData';
import { TimePicker } from '@mui/x-date-pickers';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import New_Event_Dash from './newEventD';
import dayjs from 'dayjs';

export default function Event_Dash() {

const [events,setEvents]=useState([])
const [data,setData]=useState({})
const [newE,setNewE]=useState(false)
const [text,setText]=useState('')
const [text1,setText1]=useState('')

const [processing,setProcessing]=useState(true)
const [edit,setEdit]=useState('')
const [editor,setEditor]=useState('')
const [active,setActive]=useState('')
const [active1,setActive1]=useState(false)
const [del,setDel]=useState(false)

const [activeI,setActiveI]=useState('')

const [activeVal1,setActiveVal1]=useState('')
const [error,setError]=useState('')
const [activeS,setActiveS]=useState('')
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)

const [reload,setReload]=useState(0)
const [event_id,setEventID]=useState('')




useEffect(() => {
  fetch(live+'events').then((res)=>res.json()).then((resp)=>eventResp(resp))
  }, [reload])


  const Reload=(val)=>{
if(val){
  setProcessing(false)
  setError(val)
  setOpen1(true)
    setReload(reload+1)
    setNewE(false)
}
else{
  setProcessing(false)
  setReload(reload+1)
}

   
  }
// console.log(edit)
  useEffect(() => {

    if(reload>0&&edit)
    fetch(live+`events?q=${edit.event_id}`).then((res)=>res.json()).then((resp)=>eventResp1(resp))
    }, [reload])

  const eventResp=(val)=>{
    setProcessing(false)
    setEvents(val.data)


}
const eventResp1=(val)=>{
  setProcessing(false)
  setEdit(val.data[0])


}




const EditS=(val,val2,val3,val4,val5)=>{
if(active===val&&activeS===val2){
EditT(val,'',val4,val5)
}
else{

  setActive(val)
  setActiveS(val2)
  setText(val3)}

}
const EditT=(val,val2,val3,val4)=>{

  if(active===val){

if(active==='event_url'){
  checkUrl()
}
else{

  setProcessing(true)
    const formdata=new FormData()
    formdata.append(`active`,active) 
    formdata.append('val', active==='show_likes_admin'&&data.checkedS?'1':active==='show_likes_admin'&&!data.checkedS?'0': active==='active'&&data.checkedS?'1':active==='active'&&!data.checkedS?'0':active==='start_date'?today:active==='end_date'?today1:active==='start_time'?time1:active==='end_time'?time2:activeS==='platform'?activeVal1:activeS==='url'?val4:text&&!text1?text:activeVal1) 
    formdata.append('event_id',edit.event_id) 
    formdata.append('sub_id',val3?val3:'') 
    formdata.append('text1',activeS==='url'?text:activeS==='platform'?val4:text1) 

    formdata.append('img_active',active.includes('imageB')&&data.checkedS?'1':'0') 





fetch(live+'events',
      {
        method:'PUT',
        body:formdata
      }).then(res=>res.json()).then((resp)=>editRes(resp))


}

    


  }

  else{
    setActive(val)
    setText(val2)
  }
  

}

const checkUrl=()=>{
  setProcessing(true)

  if(text){
    fetch(live+`event-url?q=${text}`).then((res)=>res.json()).then(resp=>urlRes(resp))

  }
}
const urlRes=(val)=>{
  if(val.status){
setError('')
changeUrl()

  }
  else{
    setProcessing(false)
    setError(val.detail)
    setOpen(true)
  }
}


const changeUrl=()=>{
  const formdata=new FormData()
  formdata.append(`active`,active) 
  formdata.append('val',text) 
  formdata.append('event_id',edit.event_id) 
  formdata.append('sub_id','') 
  formdata.append('text1','') 




fetch(live+'events',
    {
      method:'PUT',
      body:formdata
    }).then(res=>res.json()).then((resp)=>editRes(resp))



}


const editRes=(res)=>{
if(res.status){
  setOpen1(true)
  setReload(reload+1)
  setActive('')
  setError(res.detail)
  Cancel()
}

else{
  setError(res.detail)
  setActive('')
  setOpen(true)


}
}




const Editor=(val)=>{
  if(editor){
    setProcessing(true)
    const formdata=new FormData()
    formdata.append(`active`,'editor') 
    formdata.append('val',data.about_event) 
    formdata.append('event_id',edit.event_id) 
    formdata.append('sub_id','') 



fetch(live+'events',
      {
        method:'PUT',
        body:formdata
      }).then(res=>res.json()).then((resp)=>editRes(resp))

  }
  else{
    setEditor(val)
  }

}

const changeSwitch=(val,val1)=>{

    setActive(val)
    setData({
      ...data,checkedS:val1
    })
  

}

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setEventID('')


}


const Cancel=()=>{
  setActive('')
  setText('')
  setActiveVal1('')
  setActive1(false)
  setActiveI('')
  setData({
    ...data,image:''
  })
  setEditor('')
  setActiveS('')
  setText1('')



}




var dd = data&&data.start_date?String(data.start_date.$d.getDate()).padStart(2, '0'):'';
var mm = data&&data.start_date?String(data.start_date.$d.getMonth() + 1).padStart(2, '0'):''; //January is 0!
var yyyy = data&&data.start_date?data.start_date.$d.getFullYear():'';

let today = yyyy+'-'+mm+'-'+dd;

var dd1 = data&&data.end_date?String(data.end_date.$d.getDate()).padStart(2, '0'):'';
var mm1 = data&&data.end_date?String(data.end_date.$d.getMonth() + 1).padStart(2, '0'):''; //January is 0!
var yyyy1 = data&&data.end_date?data.end_date.$d.getFullYear():'';

let today1 = yyyy1+'-'+mm1+'-'+dd1;
let time1=data.start_time?(data.start_time.$d.getHours()+':'+data.start_time.$d.getMinutes()):'';
let time2=data&&data.end_time?(data.end_time.$d.getHours()+':'+data.end_time.$d.getMinutes()):'';



const Delete=(val)=>{

  
if(event_id){
  setOpen2(false)
  setDel(true)
  const formdata=new FormData()
    formdata.append(`event_id`,event_id)
  fetch(live+'events',
  {
    method:'Delete',
    body:formdata
  }).then(res=>res.json()).then((resp)=>delRes(resp))



}
else{
  setEventID(val)
setOpen2(true)
}
}

const delRes=(resp)=>{
  if(resp.status){
setDel(false)
setError(resp.detail)
setReload(reload+1)
setOpen1(true)
setEventID('')
  }
  else{
    setDel(false)

    setError(resp.detail)
    setOpen(true)
    setEventID('')


  }

}

const renderEdit = (val) => {
  return (
      <strong>
         <Button onClick={()=>Edit(val.row.eventID)} className='btn_evd2'>Edit</Button>
      </strong>
  )
}
const renderActive = (val) => {
  return (
      <strong>
        {val.row.Active?<i  className="fas fa-check-circle icon_review"></i>:<i  className="fas fa-times-circle icon_review"></i>}
      </strong>
  )
}


// console.lodayjsg(new Date().getFullYear().toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0')+'T'+ edit.start_time)
const renderDelete = (val) => {
  return (
      <strong>
                 <Button onClick={()=>Delete(val.row.eventID)} className='btn_evd3'>{event_id===val.row.eventID&&del?'Deleting':'Delete'}</Button>

         {/* <Button onClick={()=>Delete(val.row.eventID)} className='btn_evd3'>Delete</Button> */}
      </strong>
  )
}

const Edit=(id)=>{
  setEdit(events.find(val=>val.event_id===id))
}


const quillChange=(val)=>{
  setData(data => ({ ...data, about_event:val}))
  
 }
  const rows= events.map((item,k) => {
    return  {
     eventID:item.event_id,

      id:k+1,
      Name: item.event_name,
      Organiser: item.event_organiser,
      Category: item.category,
      "Event Type":item.event_type,
      "Event Mode":item.event_mode,
      "Event Url":item.event_url,

      Start:item.start_date,
      Finish:item.end_date,
      "Created By":item.created_by,
      "Address":item.addressLine1,
      "City":item.city,
      "State":item.stat,
      "Country":item.country,
      "Pincode":item.pincode,
      "Created On":item.created_on,
      "Active":item.is_active



      



     
    }
}
  )
const columns = [
  { field: 'id', headerName: 'S No.', width: 90 },
  { field: 'eventID', headerName: 'Event ID' },

  {
    field: 'Name',
    headerName: 'Name',
   
  },
  {
    field: 'Organiser',
    headerName: 'Organiser',
  
  },
  {
    field: 'Category',
    headerName: 'Category',
    // type: 'number',
  },
  {
    field: 'Event Type',
    headerName: 'Event Type',
    // type: 'number',
  }, {
    field: 'Event Mode',
    headerName: 'Event Mode',
    // type: 'number',
  },
  {
    field: 'Event Url',
    headerName: 'Event Url',
    // type: 'number',
  }, {
    field: 'Start',
    headerName: 'Start',
    // type: 'number',
  }, {
    field: 'Finish',
    headerName: 'Finish',
    // type: 'number',
  },
  {
    field: 'Created By',
    headerName: 'Created By',
    // type: 'number',
  },
  {
    field: 'Address',
    headerName: 'Address',
    // type: 'number',
  },
  {
    field: 'City',
    headerName: 'City',
    // type: 'number',
  },
  {
    field: 'State',
    headerName: 'State',
    // type: 'number',
  },
  {
    field: 'Country',
    headerName: 'Country',
    // type: 'number',
  },

  {
    field: 'Pincode',
    headerName: 'Pincode',
    // type: 'number',
  },

  {
    field: 'Created On',
    headerName: 'Created On',
    // type: 'number',
  },




  {
    field: 'Active',
    headerName: 'Event Active',
    sortable: false,
    renderCell: renderActive,
    width: 160,
     },


  {
    field: 'Edit',
    headerName: ' ',
    sortable: false,
    renderCell: renderEdit,
     },
     {
      field: 'Delete',
      headerName: ' ',
      sortable: false,
      renderCell: renderDelete,
       },
];


const dropDownVal=(val)=>{
  setActiveVal1(val)
  setText('')
  setActive1(false)

}





const ImageCover1=(img,val)=>{

  
      
  if(img&&img.name)

{    if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  {
    
    if(img.size/1048576 <4){
      setActiveI(val)
setData({...data,image:img})  

  }
     else{
       setError('File should be <4MB')
       setOpen(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen(true)

   }}
 
}

const EditImage=(val)=>{
  if(data.image&&activeI){

    setProcessing(true)
    const formdata=new FormData()
    formdata.append(`active`,'image') 
    formdata.append('val',data.image) 
    formdata.append('event_id',edit.event_id) 
    formdata.append('sub_id',val?val:'') 


   
fetch(live+'events',
      {
        method:'PUT',
        body:formdata
      }).then(res=>res.json()).then((resp)=>editRes(resp))

  }
}
const eventChange=()=>{
  setNewE(false)
  setReload(reload+1)

}

const EditBack=(val)=>{
  setReload(reload+1)

  setEdit(val)
}
  return (
<div>
<Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Delete()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Delete event permanently
</AlertTitle>

      </Alert>
     
     
      </Snackbar>

{!edit&&!newE?
<Grid container style={{minHeight:'80vh'}}>

        <Grid container style={{alignItems:'center',marginBottom:'5vh'}} >
<Grid item sm={9} xs={8}>
<TextField placeholder='Search events here ..' className='field_evd' />
</Grid>
<Grid item sm={3} xs={4}>
<Button onClick={()=>setNewE(true)} variant='contained' className='btn_evd'>Create New event</Button>
</Grid>

        </Grid>

        

<Grid container>
{events&&events[0]&&!processing?<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:!events[0]&&!processing?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Events Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>

        </Grid>
        
        
        :!edit&&newE?
        <New_Event_Dash eventChange={eventChange} Reload={Reload} />
        :<Grid style={{minHeight:'80vh',paddingBottom:'5vh',paddingTop:'17vh',alignContent:'flex-start'}} container>
    <Grid item style={{marginBottom:'2vh',textAlign:'end',paddingRight:20}} sm={12} xs={12}>
    <Chip style={{marginBottom:'2vh'}} onClick={()=>EditBack(false)}   className='chip_evd' label={'Back'} />

     </Grid> 
    <Grid item style={{marginBottom:'2vh'}} sm={6} xs={6}>
     <Typography className='txt_evd'>
   
   Edit {edit.event_organiser} Event
     </Typography>
     </Grid> 
     <Grid style={{textAlign:'center'}} container sm={6} xs={6}>
     <Grid style={{textAlign:'center'}} item sm={8} xs={8}>
     <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active==='active'?data.checkedS:edit.is_active)}  onChange={(e)=>changeSwitch('active',e.target.checked)} />} label="Active" />
    </FormGroup>
    </Grid> 

    {  active==='active'? <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('active',edit.active)}   className='chip_evd' label={active==='active'&&processing?'Saving':active==='active'&&!processing? 'Save':'Edit Event Name'} />
    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />

    
     </Grid>:null}

     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='event_name'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.event_name}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('event_name',edit.event_name)}   className='chip_evd' label={active==='event_name'&&processing?'Saving':active==='event_name'&&!processing? 'Save':'Edit Event Name'} />
{  active==='event_name'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 


     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='event_description'? <TextField multiline minRows={3} value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.event_description}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('event_description',edit.event_description)}   className='chip_evd' label={active==='event_description'&&processing?'Saving':active==='event_description'&&!processing? 'Save':'Edit Event Description'} />
{  active==='event_description'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 





     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
 {active==='category'?    <Grid     item sm={12} xs={12}>
  <Chip

  label={!activeVal1?edit.category:activeVal1}
  onClick={()=>setActive1(!active1)}
  onDelete={()=>setActive1(!active1)}
  deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
  className='chip_drop'
/>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {CategoriesMain.map((val,idx)=>
  <li onClick={()=>dropDownVal(val.category)} key={idx} className="list_filter">
  {val.category}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>:

     <Typography className='value_evd'>
   {edit.category}
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>EditT('category',edit.event_organiser)}  label={active==='category'&&processing?'Saving':active==='category'&&!processing?'Save':'Edit Event Category'} />
     {  active==='category'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
 {active==='event_type'?    <Grid     item sm={12} xs={12}>
  <Chip

  label={!activeVal1?edit.event_type:activeVal1}
  onClick={()=>setActive1(!active1)}
  onDelete={()=>setActive1(!active1)}
  deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
  className='chip_drop'
/>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>:

     <Typography className='value_evd'>
   {edit.event_type}
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>EditT('event_type',edit.event_organiser)}  label={active==='event_type'&&processing?'Saving':active==='event_type'&&!processing?'Save':'Edit Event Type'} />
     {  active==='event_type'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
 {active==='event_mode'?    <Grid     item sm={12} xs={12}>
  <Chip

  label={!activeVal1?edit.event_mode:activeVal1}
  onClick={()=>setActive1(!active1)}
  onDelete={()=>setActive1(!active1)}
  deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
  className='chip_drop'
/>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Mode.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>:

     <Typography className='value_evd'>
   {edit.event_mode}
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>EditT('event_mode',edit.event_organiser)}  label={active==='event_mode'&&processing?'Saving':active==='event_mode'&&!processing?'Save':'Edit Event Mode'} />
     {  active==='event_mode'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='event_organiser'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.event_organiser}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('event_organiser',edit.event_organiser)}   className='chip_evd' label={active==='event_organiser'&&processing?'Saving':active==='event_organiser'&&!processing? 'Save':'Edit Event Organiser'} />
{  active==='event_organiser'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item style={{textAlign:activeI==='image'&&data.image? '-webkit-center':'justfy'}} sm={8} xs={8}>


   {activeI==='image'&&data.image? <Card  sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={URL.createObjectURL(data.image)}
        alt="Event"
      />
      </Card>:  <Typography className='text1_evd'>
  Event Images
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',display:'flex'}} xs={4}>
     <div onClick={()=>EditImage()}  style={{display:'flex', justifyContent:'space-around' , backgroundColor: 'var(--pinkColor)',borderRadius:'30px',height:'33px',width:'56%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image&&activeI===`image`&&processing?'Saving':data.image&&activeI===`image`&&!processing?'Save':'Add New Image'}</label>
                  </div>

                  <input type={data.image?'button':'file'} id={`file`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0],`image`)}/><br />
         
                  {  activeI===`image`? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
  
    
     </Grid>
     </Grid> 
     {edit.images.map((val,idx)=><Grid key={idx} container style={{paddingTop:'3vh'}} sm={12} xs={12}>

     {idx>0?<>
      <Grid item style={{marginBottom:'2vh'}} sm={6} xs={6}>
    
     </Grid> 
     <Grid style={{textAlign:'center'}} container sm={6} xs={6}>
     <Grid style={{textAlign:'center'}} item sm={8} xs={8}>
     <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active===`imageB${idx}`?data.checkedS:val.is_active)}  onChange={(e)=>changeSwitch(`imageB${idx}`,e.target.checked)} />} label={`Active Image${idx+1}` }/>
    </FormGroup>
    </Grid> 

    {  active===`imageB${idx}`? <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT(`imageB${idx}`,val.is_active,val.id)}   className='chip_evd' label={active===`imageB${idx}`&&processing?'Saving':active===`imageB${idx}`&&!processing? 'Save':'Active Image'} />
    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />

    
     </Grid>:null}

     </Grid> 
</>:null}
      
     <Grid  style={{textAlign:'-webkit-center'}}item sm={8} xs={8}>
     <Card  sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={activeI===`image${idx}`&&data.image?URL.createObjectURL(data.image):img_link+val.image}
        alt="Event"
      />
      </Card>
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center',display:'flex'}} xs={4}>
     <div  onClick={()=>EditImage(val.id)} style={{justifyContent:'space-around' , backgroundColor: 'var(--pinkColor)',borderRadius:'30px',height:'40%',width:'56%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file${idx}`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image&&activeI===`image${idx}`&&processing?'Saving':data.image&&activeI===`image${idx}`&&!processing?'Save':`Edit Image${idx+1}`}</label>
                  </div>

                  <input type={data.image?'button':'file'} id={`file${idx}`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0],`image${idx}`,val.id)}/><br />
         
                  {  activeI===`image${idx}`? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> )}
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='start_date'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Event Start Date"
        value={data.start_date?data.start_date:new Date(edit.start_date)}
        onChange={(newValue) => {
          setData({...data,start_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.start_date}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('start_date',edit.start_date)}   className='chip_evd' label={active==='start_date'&&processing?'Saving':active==='start_date'&&!processing? 'Save':'Edit Start Date'} />
{  active==='start_date'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='end_date'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Event End Date"
        value={data.end_date?data.end_date:new Date(edit.end_date)}
        onChange={(newValue) => {
          setData({...data,end_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.end_date}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('end_date',edit.end_date)}   className='chip_evd' label={active==='end_date'&&processing?'Saving':active==='end_date'&&!processing? 'Save':'Edit End Date'} />
{  active==='end_date'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='start_time'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Event Start Time"
        value={dayjs(data.start_time?data.start_time:new Date().getFullYear().toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0')+'T'+ edit.start_time)}
        onChange={(newValue) => {
          setData({...data,start_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.start_time}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('start_time',edit.start_time)}   className='chip_evd' label={active==='start_time'&&processing?'Saving':active==='start_time'&&!processing? 'Save':'Edit Start Time'} />
{  active==='start_time'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     {active==='end_time'?<LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Event End Time"
        value={dayjs(data.end_time?data.end_time:new Date().getFullYear().toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0')+'T'+ edit.end_time)}
        onChange={(newValue) => {
          setData({...data,end_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>:
     <Typography className='value_evd'>
   {edit.end_time}
  
     </Typography>}
        </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT('end_time',edit.end_time)}   className='chip_evd' label={active==='end_time'&&processing?'Saving':active==='end_time'&&!processing? 'Save':'Edit End Time'} />
{  active==='end_time'? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
     </Grid>
     </Grid>  
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='addressLine1'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.addressLine1}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('addressLine1',edit.addressLine1)}   className='chip_evd' label={active==='addressLine1'&&processing?'Saving':active==='addressLine1'&&!processing? 'Save':'Edit Event Address'} />
{  active==='addressLine1'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='city'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.city}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('city',edit.city)}   className='chip_evd' label={active==='city'&&processing?'Saving':active==='city'&&!processing? 'Save':'Edit City'} />
{  active==='city'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='stat'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.stat}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('stat',edit.stat)}   className='chip_evd' label={active==='stat'&&processing?'Saving':active==='stat'&&!processing? 'Save':'Edit Stat'} />
{  active==='stat'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='country'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.country}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('country',edit.country)}   className='chip_evd' label={active==='country'&&processing?'Saving':active==='country'&&!processing? 'Save':'Edit Country'} />
{  active==='country'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='pincode'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.pincode}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('pincode',edit.pincode)}   className='chip_evd' label={active==='pincode'&&processing?'Saving':active==='pincode'&&!processing? 'Save':'Edit Pincode'} />
{  active==='pincode'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='map_url'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.map_url}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('map_url',edit.map_url)}   className='chip_evd' label={active==='map_url'&&processing?'Saving':active==='map_url'&&!processing? 'Save':'Edit Map Url'} />
{  active==='map_url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='iframe_url'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:  
  <Grid  dangerouslySetInnerHTML={{__html: edit.iframe_url}} style={{maxWidth:'70%',marginTop:'2vh'}} item sm={12} xs={12}>

 
  </Grid>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('iframe_url',edit.iframe_url)}   className='chip_evd' label={active==='iframe_url'&&processing?'Saving':active==='iframe_url'&&!processing? 'Save':'Edit Map iframe'} />
{  active==='iframe_url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {!editor?<Typography style={{    maxHeight: "350px",
    overflow: 'scroll',
    textAlign: 'justify'
}} dangerouslySetInnerHTML={{__html: edit.about_event}} className='value_evd'>
  
     </Typography>:<MyEditorS quillChange={quillChange} editor={editor}  />}

     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center'}} xs={4}>
     <Chip className='chip_evd' onClick={()=>Editor(edit.about_event)} label={editor?'Save':'Edit About Event'} />
     {  editor?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {active===`tag`? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:
     <Typography className='text1_evd'>
  Event Tags
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
     <Chip  onClick={()=>EditT(`tag`,'')}   className='chip_evd' label={active===`tag`&&processing?'Saving':active===`tag`&&!processing? 'Save':`Add New Tag`} />
  {  active===`tag`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
  
    
     </Grid>
     </Grid>
      {edit.tags.map((val,idx)=>
       <Grid key={idx} container style={{paddingTop:'3vh'}} sm={12} xs={12}>
       <Grid item sm={8} xs={8}>
    {active===`tag${idx}`? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
     {val.tag}
    
       </Typography>}
       </Grid> 
       <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
       <Chip  onClick={()=>EditT(`tag${idx}`,val.tag,val.id)}   className='chip_evd' label={active===`tag${idx}`&&processing?'Saving':active===`tag${idx}`&&!processing? 'Save':`Edit Tag${idx+1}`} />
  {  active===`tag${idx}`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
       </Grid>
       </Grid>  ) }
  
     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={8} xs={8}>
     {active===`social`?<>
<Grid itemsm={12} xs={12}>
     <Chip

label={!activeVal1?'':activeVal1}
onClick={()=>setActive1(!active1)}
onDelete={()=>setActive1(!active1)}
deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/></Grid>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
<div className="filter_values">
<ul>
{Events_Platform.map((val,idx)=>
<li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
{val}
</li>) 
}


</ul>
</div>

</div>:null}
     <TextField value={text1} onChange={(e)=>setText1(e.target.value)} label='Social Media Url' className='txt_field_evd' style={{marginTop:'2vh'}} />

     </> :
     <Typography className='text1_evd'>
   Event Social Media Platforms
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',alignSelf:'center'}} xs={4}>
     <Chip  onClick={()=>EditT(`social`,'')}   className='chip_evd' label={active===`social`&&processing?'Saving':active===`social`&&!processing? 'Save':`Add New Platform`} />
  {  active===`social`?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
  :null}
      
  
    
     </Grid>
     </Grid>
    {edit.social_media.map((val,idx)=>
    <Grid container key={idx} style={{paddingTop:'3vh'}} sm={12} xs={12}>
      <Grid item sm={12} xs={12}>
        <Typography className='text1_evd'>{val.platform}</Typography>
        </Grid>
    <Grid item style={{marginBottom:'2vh'}} sm={8} xs={8}>
    {active===`social${idx}`&&activeS==='platform'? 
    <>
    <Grid  item sm={12} xs={12}>
     <Chip

label={!activeVal1?val.platform:activeVal1}
onClick={()=>setActive1(!active1)}
onDelete={()=>setActive1(!active1)}
deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/></Grid>
{active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
<div className="filter_values">
<ul>
{Events_Platform.map((val,idx)=>
<li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
{val}
</li>) 
}


</ul>
</div>

</div>:null}
    </>
    :
    <Typography className='value_evd'>
  {val.platform}
 
    </Typography>}
    </Grid> 
    <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
    <Chip  onClick={()=>EditS(`social${idx}`,'platform',val.platform,val.id,val.url)}   className='chip_evd' label={active===`social${idx}`&&activeS==='platform' &&processing?'Saving':active===`social${idx}`&&activeS==='platform' &&!processing? 'Save':`Edit Platform${idx+1}`} />
{  active===`social${idx}`&&activeS==='platform'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null} 
   
    </Grid>
    <Grid item sm={8} xs={8}>
    {active===`social${idx}`&&activeS==='url'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:

    <Typography className='value_evd'>
  {val.url}
 
    </Typography>}
    </Grid> 
    <Grid item sm={4} style={{textAlign:'start'}} xs={4}>
    <Chip  onClick={()=>EditS(`social${idx}`,'url',val.url,val.id,val.platform)}   className='chip_evd' label={active===`social${idx}`&&activeS==='url'&&processing?'Saving':active===`social${idx}`&&activeS==='url'&&!processing? 'Save' :`Edit ${val.platform} Url`} />
{  active===`social${idx}`&&activeS==='url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null} 
   
    </Grid>
    </Grid> 
    
    
    
    
    ) }

    
    <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='meta_title'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.meta_title}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('meta_title',edit.meta_title)}   className='chip_evd' label={active==='meta_title'&&processing?'Saving':active==='meta_title'&&!processing? 'Save':'Edit Meta Title'} />
{  active==='meta_title'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='meta_description'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.meta_description}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('meta_description',edit.meta_description)}   className='chip_evd' label={active==='meta_description'&&processing?'Saving':active==='meta_description'&&!processing? 'Save':'Edit Meta Decription'} />
{  active==='meta_description'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='meta_keywords'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.meta_keywords}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('meta_keywords',edit.meta_keywords)}   className='chip_evd' label={active==='meta_keywords'&&processing?'Saving':active==='meta_keywords'&&!processing? 'Save':'Edit Meta Keywords'} />
{  active==='meta_keywords'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='event_url'? <TextField value={text} onChange={(e)=>setText(e.target.value.replace(' ','_'))} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.event_url}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('event_url',edit.event_url)}   className='chip_evd' label={active==='event_url'&&processing?'Saving':active==='event_url'&&!processing? 'Save':'Edit Event Url'} />
{  active==='event_url'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
  {active==='admin_likes'? <TextField value={text} onChange={(e)=>setText(e.target.value)} className='txt_field_evd' />:   <Typography className='value_evd'>
   {edit.admin_likes}
  
     </Typography>}
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('admin_likes',edit.admin_likes)}   className='chip_evd'  label={active==='admin_likes'&&processing?'Saving':active==='admin_likes'&&!processing? 'Save':'Edit Admin Likes'} />
{  active==='admin_likes'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={8} xs={8}>
     <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch style={{color:'var(--pinkColor)'}}  checked={(active==='show_likes_admin'?data.checkedS:edit.show_likes_admin)}  onChange={(e)=>changeSwitch('show_likes_admin',e.target.checked)} />} label="Show Admin Likes" />
    </FormGroup>
     </Grid> 
     <Grid item style={{textAlign:'start'}} sm={4} xs={4}>
     <Chip  onClick={()=>EditT('show_likes_admin',edit.show_likes_admin)}   className='chip_evd' label={active==='show_likes_admin'&&processing?'Saving':active==='show_likes_admin'&&!processing? 'Save':'Edit Show Admin Likes '} />
{  active==='show_likes_admin'?    <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
    
     </Grid>
     </Grid> 
       </Grid>}

        
        </div>
  )
}
