import { Button, Card, CardContent, CardMedia, CircularProgress, Container, Dialog, Grid, IconButton, InputAdornment, OutlinedInput, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState,useRef } from 'react'
import '../homepage/home_top/home_top.css'
import './explore.css'
import {useCookies} from 'react-cookie'

import { CategoriesMain, Categories_Explore, SearchCategories, SearchCategoriesExplore } from '../homepage/homeData';
import SignIND from '../auth/signinD';
import { img_link, live } from '../../App';
import moment from 'moment'
export default function Explore() {
  const myRef = useRef(null)

  const[activeC,setActiveC]=useState('All')
  const [country,setCountry]=useState('')
const [explores,setExplores]=useState([])
  const [liked,setLiked]=useState(false)
  const [openD,setOpenD]=useState(false)
  const[reload,setReload]=useState(0)
  const[auth]=useCookies(['auth-happ'])
  const [open,setOpen]=useState(false)
  const [address,setAddress]=useState('')
  const [suggestions,setSuggestions]=useState('')

  const [processing,setProcessing]=useState(true)
  
  
  
  // let filterCat=explores&&explores[0]&&activeC!=='All'?(explores.filter(val=>(val.category.includes(activeC)||val.explore_type.includes(activeC)||val.explore_mode.includes(activeC)
  // ||val.explore_name.includes(activeC)))):''


  let filter2=explores&&explores[0]&&activeC==='All'?explores:explores&&explores[0]&&activeC!=='All'&&activeC!=='Today'&&activeC!=='This weekend'?(explores.filter(val=>(val.category.toLowerCase().includes(activeC.toLowerCase())||val.explore_type.toLowerCase().includes(activeC.toLowerCase())||val.explore_mode.toLowerCase().includes(activeC.toLowerCase())
||val.explore_name.toLowerCase().includes(activeC.toLowerCase())||val.explore_mode.toLowerCase().includes(activeC.toLowerCase())||val.addressLine1.toLowerCase().includes(activeC.toLowerCase())||val.city.toLowerCase().includes(activeC.toLowerCase())||val.stat.toLowerCase().includes(activeC.toLowerCase())||val.country.toLowerCase().includes(activeC.toLowerCase())))):explores&&explores[0]&&activeC=='Today'?(explores.filter(val=>(moment(new Date(val.start_date)).format("DD MMM YYYY"))===((moment(new Date()).format("DD MMM YYYY")))||(moment(new Date(val.end_date)).format("DD MMM YYYY"))===((moment(new Date()).format("DD MMM YYYY")))
)):explores&&explores[0]&&activeC=='This weekend'?(explores.filter(val=>
  (moment(new Date(val.start_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (6-new Date().getDay())))).format("DD MMM YYYY")))||
  (moment(new Date(val.end_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (6-new Date().getDay())))).format("DD MMM YYYY")))||
  (moment(new Date(val.start_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (7-new Date().getDay())))).format("DD MMM YYYY")))||(moment(new Date(val.end_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (7-new Date().getDay())))).format("DD MMM YYYY")))
)):''
  
    useEffect(() => {
   fetch('https://ipapi.co/json').then(res=>res.json()).then((resp)=>Country(resp))
    }, [])
    
    const Country=(val)=>{

      setCountry(val)
      fetch(live+`explore?city=${val.city}&state=${val.region}`).then(res=>res.json()).then((resp)=>Explores(resp))
    
    
    }
  useEffect(() => {
    if(reload>0){
      fetch(live+`explore?city=${address.city?address.city:country.city}&state=${address.city?address.stat:country.state}`).then((res)=>res.json()).then(resp=>Explores(resp))
     
    }
  
     },[reload])
  const Explores=(val)=>{
    setProcessing(false)
    setLiked('')
  setExplores(val.data)
  }
  
  const Follow=(val)=>{
    if(auth&&auth['auth-happ']){
      setLiked(val)
      const formdata =new FormData()
      formdata.append('liked_by',auth['auth-happ'].user_id)
      formdata.append('explore',val)
  fetch(live+'explore-url',
  {
    method:'PUT',
    body:formdata
  }).then(resp=>resp.json()).then(res=>likeRes(res))
    }
    else{
      setOpenD(true)
    }
  }
  
  const likeRes=()=>{
    setReload(reload+1)
  }
  
  const handleClose=()=>{
    setOpenD(false)
  }


  const Address=(val)=>{
    setOpen(true)
    // setAddress(val)
    const autocomplete = new window.google.maps.places.AutocompleteService();
    autocomplete.getPlacePredictions({
      input:val
    }).then((res)=>setSuggestions(res.predictions))
  
  }



  const Address1=(val)=>{
    setOpen(false)
    window.scrollTo(0, 0);
    setProcessing(true)
  
    fetch(live+`explore?city=${val.terms[0].value}&state=${val.terms[1].value}`).then((res)=>res.json()).then(resp=>Explores(resp))
  
    setAddress({
      ...address,city:val.terms[0].value,stat:val.terms[1].value
  
    })
  
   }

   const executeScroll=()=>{

    if(myRef&&myRef.current)
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  
  
  }
  const ActiveC=(val)=>{
    setActiveC(val)
    executeScroll()
   }
  if (!processing){
  return (
    <div >
        <div>
        <Dialog
      className='dialog_signin'
      disableScrollLock={ true }
      open={openD}
      style={{textAlign:'center',margin:10}}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<SignIND handleClose={handleClose} />

    </Dialog>
            <img src={require('../../images/explore.jpg')} alt='home' className='home_topI' />
        </div>
        <Grid className='grid_home'   item sm={12} xs={12}>
        <div class="max-widthE">
          <div class="crousel-contentE">
          <h2>Let's Explore. Let's Live
</h2>
            <p>
            Explore millions of live offers and discover deals, latest offers, shops and more..
            </p>
          
          </div>
        </div>
      
        </Grid>
        <Container style={{maxWidth:'1350px'}} item sm={12} xs={12}>
         <Grid style={{marginTop:'4vh',textAlign:'-webkit-center'}} container> 
         <Grid className='grid_card'  item sm={4} xs={12}>
    <Card  style={{borderRadius:10}} sx={{ maxWidth: 340 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="180"
        image={require('../../images/Food.jpg')}
        alt="Event"
      />
        <CardContent >
    
        <Typography className='typo_eventTime'>
              <b>Explore Restaurants</b>

          </Typography>
          <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
         Explore restaurants with great deals
          </Typography>
    
        </CardContent>
       
      </Card>
    </Grid>
  
    <Grid className='grid_card'  item sm={4} xs={12}>
    <Card  style={{borderRadius:10}} sx={{ maxWidth: 340 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="180"
        image={require('../../images/explore.jpg')}
        alt="Event"
      />
        <CardContent >
    
        <Typography className='typo_eventTime'>
        <b>Explore Shopping</b>
          </Typography>
          <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
          Explore deals and offers 
          </Typography>
    
        </CardContent>
       
      </Card>
    </Grid>
    <Grid className='grid_card'  item sm={4} xs={12}>
    <Card  style={{borderRadius:10}} sx={{ maxWidth: 340 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="180"
        image={require('../../images/EV_2.jpg')}
        alt="Event"
      />
        <CardContent >
    
        <Typography className='typo_eventTime'>
        <b>  Explore Businesses</b>            </Typography>
          <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
          Explore millions of nearby businesses
          </Typography>
    
        </CardContent>
       
      </Card>
    </Grid>
          </Grid>
          <Grid style={{paddingTop:'3vh'}} item sm={12} xs={12}>
          <Typography className='typo_happ'>
            Discover Around  <span onClick={()=>setOpen(!open)} style={{cursor:'pointer'}}>{address.city?address.city:country.city}</span>{open?<i class="fas fa-times" style={{cursor:'pointer'}}onClick={()=>setOpen(!open)}></i>:<i onClick={()=>setOpen(!open)} style={{cursor:'pointer'}} class="fas fa-map-marker-alt" ></i>}
{open?          <div style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:323,alignSelf:'start',alignContent:'start',borderRadius:5,minHeight:80,padding:10,position:'absolute',zIndex:1}}> 

          <TextField  style={{width:"90%",height:40}} variant="standard" placeholder='Search for cities or address' onChange={(e)=>Address(e.target.value)}  />


{suggestions&&suggestions[0]&&open?<div  style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:'100%',alignSelf:'start',alignContent:'start',borderRadius:5,maxHeight:400,overflowY:'scroll'}}>
{suggestions.map((suggestion,idx)=>(

  <Grid onClick={()=>Address1(suggestion)} key={idx}  className='cont_search' container>

<Grid item  sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='main_txt'>{suggestion.terms[0].value}</Typography>
  </Grid>
 <Grid item sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='sub_txt'>{suggestion.description}</Typography></Grid>
  
 
  
  </Grid>)
)}  
</div>:null}  
</div>:null}

            
          </Typography>
          </Grid>
          <Grid container style={{paddingTop:'2vh'}} >
            {SearchCategoriesExplore.map((val,idx)=>
           <Typography key={idx} onClick={()=>ActiveC(val)}  className={activeC===val?'typo_catA':'typo_cat'}>
{val}
           </Typography> )}
          </Grid>


          <Grid item sm={12} xs={12}>
<Typography className='trending_head'>
Check out trending categories
</Typography>
  </Grid>
  <Grid container style={{marginBottom:'20px'}} >
    {Categories_Explore.map((val,idx)=>
    <Grid className='grid_card' key={idx} item sm={3} xs={6}>
    <Card  onClick={()=>ActiveC(val.category)} sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={val.image}
        alt="Event"
      />
        <CardContent className='card_category'>
        <Typography className='typo_card_category' gutterBottom variant="h5" component="div">
          {val.category}
        </Typography>
        </CardContent>
      </Card>
    </Grid>)}
  </Grid>
  <div ref={myRef}>

  {  
(activeC=='All'&&explores[0]||(filter2&&filter2.length>0))?<Grid item sm={12} xs={12}>
  <Typography  className='trending_head'>
 {activeC} Deals 
  </Typography>
    </Grid>:  
<Grid item style={{color:'var(--pinkColor)',textAlign:'center'}} sm={12} xs={12}>
  <Typography className='trending_head'>
 No {activeC=='All'?'':activeC} Deals found
  </Typography>
    </Grid>}

  {/* <Grid container style={{marginBottom:'20px'}} >
    {CategoriesMain.map((val,idx)=>
    <Grid className='grid_card' key={idx} item sm={3} xs={12}>
    <Card className='card_event' sx={{ maxWidth: 300 }}>
      <CardMedia
        component="img"
        height="160"
        image={require('../../images/explore.jpg')}
        alt="Event"
      />
        <CardContent >
          <div className='like_event'>
          <i class="far fa-heart icon_event"></i>
        </div>
        <Typography className='typo_eventHead'>
        "Come Meet A Black Person" Anti-Racism Virtual Series
        </Typography>
        <Typography className='typo_eventTime'>
        Sun, December 11, 2022
          </Typography>
          <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
         3:00 PM – 5:00 PM EST
          </Typography>
          <Typography className='typo_eventAuth'>
          
          Chantelle Fitzgerald
          </Typography>
          <Typography className='typo_eventFollow'>
         <span><i class="far fa-user icon_user"></i></span> 6.2k followers
          </Typography>
        </CardContent>
      </Card>
    </Grid>)}
  </Grid> */}

{activeC=='All'||(filter2&&filter2.length>0) ?<Grid container style={{marginBottom:'20px'}} >


{(activeC!=='All'?filter2:explores).map((val,idx)=>
{
// console.log(moment(val.start_time))

  return(
  <Grid className='grid_card' key={idx} item sm={3} xs={12}>
<Card  className='card_event' sx={{ maxWidth: 300 }}>
  <CardMedia 
  onClick={()=>window.open(`/explore/${val.explore_url}`)}
  // className='img_card'
    component="img"
    height="160"
    image={img_link+val.images[0].image}
    alt="Event"
  />
    <CardContent >
      <div  className='like_event'>
      {(val.likes!=null&&auth['auth-happ']&&val.likes.length>0&&val.likes.find(val=>val.user_id===auth['auth-happ'].user_id)||liked===val.explore_id)?<i class="fas fa-heart icon_event" ></i>:<i class="far fa-heart icon_event" onClick={()=>Follow(val.explore_id)}></i>}
    </div>
    <div onClick={()=>window.open(`/explore/${val.explore_url}`)}>
    <Typography className='typo_eventHead'>
{val.explore_name}          </Typography>
    <Typography className='typo_eventTime'>
    {moment(val.start_date).format("MMMM Do YYYY")+' - '+moment(val.end_date).format("MMMM Do YYYY")}
      </Typography>
      <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
      {/* {moment(val.start_time._i).format('LT').toString()+' - '+moment(val.end_time._i).format('LT').toString()} */}
      {val.start_time.slice(0,5).toString()+' - '+val.end_time.slice(0,5).toString()}
      {/* {new Date(val.start_time).getHours().toString()+':'+new Date(val.start_time).getMinutes().toString()+' - '+new Date(val.end_time).getHours().toString()+':'+new Date(val.end_time).getMinutes().toString()} */}
      </Typography>
      <Typography className='typo_eventAuth'>
      
      {val.explore_organiser}
      </Typography>
      <Typography className='typo_eventFollow'>
     <span><i class="far fa-user icon_user"></i></span> {val.show_likes_admin&&liked===val.explore_id?val.admin_likes+1:val.show_likes_admin&&liked!==val.explore_id?val.admin_likes:!val.show_likes_admin&&liked===val.explore_id&&val.likes!=null?val.likes.length+1:!val.show_likes_admin&&liked===val.explore_id&&val.likes==null?1:(val.likes!==null&&val.likes.length?val.likes.length:0)} followers
      </Typography>
      </div>
    </CardContent>
  </Card>
</Grid>)})}
</Grid>:null}
</div>
        </Container>
    </div>
  )}
  else{
    return(
      <Grid sm={12} style={{textAlign:'center',height:'100vh'}} xs={12} item>
      <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh',color:'var(--pinkColor)'}} />
      </Grid>)
  }
}
