import React from 'react'
import Home_Top from './home_top/home_top'

export default function Home() {
  return (
   <>
   <Home_Top />
   </>
  )
}
