import { Alert, AlertTitle, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Container, Dialog, Grid, Paper, Snackbar, Typography } from '@mui/material'
import React, { useState,useEffect } from 'react'
import { img_link, live } from '../../App'
import { CategoriesMain, SearchCategories } from '../homepage/homeData'
import './particularEvent.css'
import { Helmet } from "react-helmet";
import {useCookies} from 'react-cookie'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import SignIND from '../auth/signinD'
import moment from 'moment'


const NextArrow = ({ onClick }) => {
    return (
      <div className="nextArrow" onClick={onClick}>
        <BsChevronRight />
      </div>
    );
  };
  
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="prevArrow" onClick={onClick}>
        <BsChevronLeft />
      </div>
    );
  };
export default function ParticularEvent(props) {

  const event_url=props.page1
const[map,setMap]=useState(false)
const[processing,setProcessing]=useState(false)
const[processing1,setProcessing1]=useState(false)

const [event,setEvent]=useState('')
const [eventsR,setEventsR]=useState('')

const [openD,setOpenD]=useState(false)
const [open1,setOpen1]=useState(false)
const [error,setError]=useState('')

const[reload,setReload]=useState(0)
const[auth]=useCookies(['auth-happ'])
const [liked,setLiked]=useState(false)

useEffect(() => {
  if(event_url){
    setProcessing(true)
    fetch(live+`event-url?q1=${event_url}`).then((res)=>res.json()).then(resp=>eventRes(resp))
   
  }
   },[])
   useEffect(() => {
    if(event&&event[0]){
      // setProcessing(true)
      fetch(live+`share?category=${event[0].category}&state=${event[0].stat}&city=${event[0].city}&type=events`).then((res)=>res.json()).then(resp=>setEventsR(resp.data))
     
    }
     },[event])

  
   useEffect(() => {
    if(reload>0){
      fetch(live+`event-url?q1=${event_url}`).then((res)=>res.json()).then(resp=>eventRes(resp))
     
    }
     },[reload])
const eventRes=(val)=>{

  if(val.data){
    setProcessing(false)
    setEvent(val.data)
    setLiked(false)
  }
    else{
      window.location.href='/'
    }
}

var settings = {
    dots: true,
    infinite: true,
  
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };

  const handleClose=()=>{
    setOpenD(false)
    setOpen1(false)
  }
// console.log(eventsR)
const Follow=()=>{
  if(auth&&auth['auth-happ']){
    setLiked(true)
    const formdata =new FormData()
    formdata.append('liked_by',auth['auth-happ'].user_id)
    formdata.append('event',event[0].event_id)
fetch(live+'event-url',
{
  method:'PUT',
  body:formdata
}).then(resp=>resp.json()).then(res=>likeRes(res))
  }
  else{
    setOpenD(true)
  }
}


const likeRes=()=>{
  setReload(reload+1)
}

const copyUrl=()=>{
  const el = document.createElement('textarea');
  el.value =`https://happ360.com/events/${event[0].event_url}`;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
setError('Event url Copied')
setOpen1(true)
}

const sendMail=()=>{
  if(auth&&auth['auth-happ']){
setProcessing1(true)
    const formdata=new FormData
    formdata.append('name',event[0].event_name)
    formdata.append('user_name',auth['auth-happ'].fname)

    formdata.append('value',`https://happ360.com/events/${event[0].event_url}`)
    formdata.append('email',auth['auth-happ'].email)


fetch(live+'share',
{
  method:'post',
  body:formdata
}).then(res=>res.json()).then(resp=>mailRes(resp))
  }
  else{
    setOpenD(true)
  }
}
const mailRes=(val)=>{
  if(val.status){
    setError(val.detail)
    setOpen1(true)
    setProcessing1(false)


  }
  else{
    setProcessing1(false)
  }
}
  
if(event&&!processing){

  return (
    <div>
{ event.map((val,idx)=>
<div key={idx}>   

<Helmet>
        {/* <!-- HTML Meta Tags --> */}

    <title>{val.meta_title}</title>
       
      
        <meta
          name="description"
          content={val.meta_description}
        />

    <meta name="keywords" content={val.meta_keywords} />
    

        {/* <!-- Google / Search Engine Tags --> */}
       
     
        <meta itemprop="name" content={val.meta_title}
         />
        
        
      
    <meta
    itemprop="description"
    content={val.meta_description}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        {/* <meta property="og:url" content="https://gettraveltips.com/" /> */}
  
        <meta property="og:type" content="website" />
   
        <meta property="og:title" content={val.meta_title} />
        
      
    <meta
          property="og:description"
          content={val.meta_description}
        />
        
      
      

      
       
      </Helmet>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>

      <Dialog
      className='dialog_signin'
      disableScrollLock={ true }
      open={openD}
      style={{textAlign:'center',margin:10}}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<SignIND handleClose={handleClose} />

    </Dialog>
    <div style={{background:'#eee'}}>
    <Container style={{maxWidth:'100%',padding:0}}>

    <div className="explore_crousel">
    <Slider {...settings}    easing='linear' lazyLoad fade  >
       {val.images.filter(val=>val.is_active==true).map((val,idx)=>
     <Grid key={idx} container className="explore_crousel_div" >
     <Grid item  sm={12} xs={12}>
   
 <img src={img_link+val.image} className='img_bg' />

     <img src={img_link+val.image} style={{maxWidth:'1200px'}} className='img_event' />
   

     </Grid>
 </Grid>  ) }
     
      

      </Slider>
      </div>
    </Container>
    </div>

    <Container style={{marginTop:'4vh',maxWidth:1300}} >
        <Grid container>
        <Grid container  sm={12} xs={12}>
            <Grid item style={{
                textAlign:'start'
            }} sm={6} xs={6}>
<Typography className='eventP_date'>
{moment(val.start_date).format("ll")}
</Typography>
</Grid>
<Grid item style={{
                textAlign:'end'
            }} sm={6} xs={6}>
{/* <i class="far fa-heart icon_eventP"></i> */}
<i  onClick={()=>copyUrl()} style={{cursor:'pointer'}} class="fas fa-copy icon_eventP"></i>
</Grid>
            </Grid>
    <Grid container style={{maxWidth:'90%',paddingRight:20}} sm={8} xs={12}>
        
            <Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_head'>
{val.event_name}
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_text'>
{val.event_description}
</Typography></Grid>
<Grid item style={{
                display:'flex',marginTop:'2vh',alignSelf:'center'
            }} sm={12} xs={12}>
<Typography className='eventP_auth'>
By <span   style={{color:'black', borderBottom:'1px solid var(--pinkColor)',paddingBottom:'5px',textDecoration:'none',fontWeight:'600'}} >{val.event_organiser}</span></Typography>
{(val.likes!=null&&val.likes.length>0&&auth['auth-happ']&&val.likes.find(val=>val.user_id===auth['auth-happ'].user_id))||liked?<Button  variant='contained' className='btn_eventP1'>Following</Button>:<Button onClick={()=>Follow()} variant='outlined' className='btn_eventP'>Follow</Button>
}<Typography style={{paddingLeft:20,paddingTop:0,alignSelf:'center'}} className='typo_eventFollow'>
           <span><i class="far fa-user icon_user"></i></span> {val.show_likes_admin&&liked?val.admin_likes+1:val.show_likes_admin&&!liked?val.admin_likes:!val.show_likes_admin&&liked?val.likes.length+1:(val.likes!==null&&val.likes.length?val.likes.length:0)} followers
            </Typography></Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>
When and where
</Typography>
</Grid>

<Grid container  sm={12} xs={12}>
            <Grid container style={{
                textAlign:'start',paddingTop:'2vh'
            }} sm={6} xs={6}>
<Grid item style={{padding:10}} sm={1} xs={1}>
<div className='icon_cont_eventP'>
<i class="fas fa-calendar-week icon_eventP_D"></i>
    </div>
</Grid>
<Grid container style={{alignSelf:'start',padding:'10px 10px 10px 25px'}} sm={11} xs={11}>
<Grid item sm={12} xs={12}>
<Typography className='W_W_head'>
Date and time
</Typography>
</Grid>
<Grid item sm={12} xs={12}>
<Typography className='W_W_text'>

{moment(val.start_date).format("MMMM Do YYYY")+', '+val.start_time.slice(0,5).toString()+' - '+moment(val.end_date).format("MMMM Do YYYY")+', '+val.end_time.slice(0,5).toString()}

{/* Tue, Dec 6, 2022, 10:00 AM – Wed, Dec 7, 2022, 5:00 PM IST */}
</Typography>
</Grid>
</Grid>
</Grid>
<Grid container style={{
                textAlign:'start',paddingTop:'2vh'
            }} sm={6} xs={6}>
<Grid item style={{padding:10}} sm={1} xs={1}>
<div className='icon_cont_eventP'>
<i class="fas fa-map-marker icon_eventP_D"></i>

    </div>
</Grid>
<Grid container style={{padding:'10px 10px 10px 25px'}} sm={11} xs={11}>
<Grid item sm={12} xs={12}>
<Typography className='W_W_head'>
Location
</Typography>
</Grid>
<Grid item sm={12} xs={12}>
<Typography className='W_W_text'>
{val.addressLine1+', '+val.city+', '+val.stat+', '+val.pincode}</Typography>
</Grid>
<Grid item onClick={()=>setMap(!map)} sm={12} xs={12}>
{map?<Typography className='W_W_text1'>
Hide Map<i class="fas fa-angle-up W_W_icon1"></i></Typography>:<Typography className='W_W_text1'>
Show Map<i class="fas fa-angle-down W_W_icon1"></i></Typography>}
</Grid>

</Grid>
</Grid>
{map?<Grid style={{maxWidth:'80%',marginTop:'2vh'}} dangerouslySetInnerHTML={{__html:val.iframe_url}}   item sm={12} xs={12}>

{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224346.54094336048!2d77.04382562832868!3d28.527252318545923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1668673443659!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" style={{border:'0px'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

</Grid>:null}

<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

About this event
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify',maxWidth:'90%',overflowX:'scroll'
            }} sm={12} xs={12}>
<Typography dangerouslySetInnerHTML={{__html: val.about_event}} className='eventP_Detail'>

</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

Tags
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify',marginTop:'2vh',marginBottom:'2vh'
            }} sm={12} xs={12}>
{val.tags.map((val,idx)=>
    <Chip label={val.tag} key={idx}  className='chip_tags'/>)}
</Grid>
{val.social_media?<><Grid item style={{
                textAlign:'justify'
            }} sm={12} xs={12}>
<Typography className='eventP_DT_head'>

Share with friends
</Typography>
</Grid>
<Grid item style={{
                textAlign:'justify',paddingTop:'2vh',paddingBottom:'3vh'
            }} sm={12} xs={12}>
{val.social_media.find(val=>val.platform.includes('Facebook'))?<i class="fab fa-facebook icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Facebook')).url,'_blank')}></i>:null}
{val.social_media.find(val=>val.platform.includes('Linked In'))?<i class="fab fa-linkedin icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Linked In')).url,'_blank')}></i>:null}
{val.social_media.find(val=>val.platform.includes('Whatsapp'))?<i class="fab fa-whatsapp icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Whatsapp')).url,'_blank')}></i>:null}

{val.social_media.find(val=>val.platform.includes('Instagram'))?<i class="fab fa-instagram icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Instagram')).url,'_blank')}></i>:null}

{val.social_media.find(val=>val.platform.includes('Snapchat'))?<i class="fab fa-snapchat-ghost icon_share"onClick={()=>window.open(val.social_media.find(val=>val.platform.includes('Snapchat')).url,'_blank')}></i>:null}


</Grid></>:null}
            </Grid>
        </Grid>
        <Grid container alignContent={'flex-start'}  sm={4} xs={12}>
<Grid container  justifyContent={'center'} sm={12} xs={12}>
<Paper elevation={3} className='ppr_sales'>
    <Grid item sm={12} xs={12}>
        <Typography className='text_sale'>
{val.event_type}
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Button variant='contained'onClick={()=>sendMail()} className='btn_sales'>
         {processing1? 'Sending': 'Share Via E-Mail'}
        </Button>
    </Grid>

</Paper>
</Grid>
{eventsR&&eventsR[0]?<Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
<div className='related_events'>
<Grid item sm={12} xs={12}>
<Typography className='related_events_head'>
Related Events
</Typography>

</Grid>
<Grid item sm={12} className='cont_relatedE' xs={12}>

{eventsR.map((val,idx)=>
    <Card style={{marginTop:'1vh'}} key={idx} className='card_event' sx={{ maxWidth: '90%' }}>
    {val.images&&val.images[0]?  <CardMedia
      // className='img_card'
        component="img"
        height="140"
        image={img_link+val.images[0].image}
        alt="Event"
      />:null}
        <CardContent >
          
        <Typography className='typo_eventHead1'>
       {val.event_name}
        </Typography>
        <Typography className='typo_eventTime'>
        {moment(val.start_date).format("MMMM Do YYYY")+', '+val.start_time.slice(0,5).toString()+' - '+moment(val.end_date).format("MMMM Do YYYY")+', '+val.end_time.slice(0,5).toString()}
          </Typography>
          {/* <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
         3:00 PM – 5:00 PM EST
          </Typography>  */}
          <Typography className='typo_eventAuth'>
          
          by {val.event_organiser}
          </Typography>
          <Typography className='typo_eventFollow'>
         <span><i class="far fa-user icon_user"></i></span> {val.show_likes_admin&&liked?val.admin_likes+1:val.show_likes_admin&&!liked?val.admin_likes:!val.show_likes_admin&&liked?val.likes.length+1:(val.likes!==null&&val.likes.length?val.likes.length:0)} followers
          </Typography>
        </CardContent>
      </Card>
)}
</Grid>

</div>

</Grid>:null}
        </Grid>
        </Grid>
    </Container>
        </div>
)}
        </div>

  )}
  else{
    return(
      <Grid sm={12} style={{textAlign:'center',height:'100vh'}} xs={12} item>
      <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh',color:'var(--pinkColor)'}} />
      </Grid>)
  }
}
