import {  Card, CardContent, CardMedia,Button, Chip, CircularProgress, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './eventDash.css'
import { img_link, live } from '../../App';
import { MyEditorS } from './editor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CategoriesMain, Events_Mode, Events_Platform, Events_Type } from '../homepage/homeData';
import { TimePicker } from '@mui/x-date-pickers';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {useCookies} from 'react-cookie'

export default function New_Event_Dash(props) {
    const[auth,setAuth]=useCookies(['auth-happ'])

const [data,setData]=useState({
})
const [text,setText]=useState('')

const [processing,setProcessing]=useState(false)
const [editor,setEditor]=useState('')
const [active,setActive]=useState('')
const [active1,setActive1]=useState('')
const [activeI,setActiveI]=useState('')

const [activeVal1,setActiveVal1]=useState('')
const [error,setError]=useState('')
const [error1,setError1]=useState('')

const [activeS,setActiveS]=useState('')
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const date1=new Date()

var dd = data&&data.start_date?String(data.start_date.$d.getDate()).padStart(2, '0'):date1.getDate().toString().padStart(2,'0');
var mm = data&&data.start_date?String(data.start_date.$d.getMonth() + 1).padStart(2, '0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy = data&&data.start_date?data.start_date.$d.getFullYear():date1.getFullYear();

let today = yyyy+'-'+mm+'-'+dd;

var dd1 = data&&data.end_date?String(data.end_date.$d.getDate()).padStart(2, '0'):date1.getDate().toString().padStart(2,'0');
var mm1 = data&&data.end_date?String(data.end_date.$d.getMonth() + 1).padStart(2, '0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy1 = data&&data.end_date?data.end_date.$d.getFullYear():date1.getFullYear();

let today1 = yyyy1+'-'+mm1+'-'+dd1;
let time1=data.start_time?(data.start_time.$d.getHours()+':'+data.start_time.$d.getMinutes()):date1.getHours()+':'+date1.getMinutes();
let time2=data&&data.end_time?(data.end_time.$d.getHours()+':'+data.end_time.$d.getMinutes()):date1.getHours()+':'+date1.getMinutes();

const createNew=()=>{

  if(data.image)
  {
    setProcessing(true)
    const formdata=new FormData()
    formdata.append('event_name',data.event_name)
    formdata.append('event_description',data.event_description)
    formdata.append('event_organiser',data.event_organiser)
    formdata.append('about_event',data.about_event)

    formdata.append('categories',data.category)
    formdata.append('userID',auth['auth-happ'].user_id)
    formdata.append('images',data.image)
    formdata.append('tags',data.tag)
    formdata.append('event_type',data.event_type)
    formdata.append('event_mode',data.event_mode)
    formdata.append('start_date',today)
    formdata.append('end_date',today1)
    formdata.append('start_time',time1)
    formdata.append('end_time',time2)
    formdata.append('map_url',data.map_url)
    formdata.append('iframe_url',data.iframe_url)

    formdata.append('event_url',data.event_url)

    formdata.append('addressLine1',data.addressLine1)
    formdata.append('city',data.city)
    formdata.append('stat',data.stat)
    formdata.append('country',data.country)
    formdata.append('pincode',data.pincode)
    formdata.append('platform',data.platform)
    formdata.append('social_url',data.social_url)
    formdata.append('meta_title',data.meta_title)
    formdata.append('meta_description',data.meta_description)

    formdata.append('meta_keywords',data.meta_keywords)

fetch(live+'events',
{
    method:'POST',
    body:formdata
}

).then((res)=>res.json()).then(resp=>newEventResp(resp))

}

else{
  setError('Image is required')
  setOpen(true)
}}

const newEventResp=(resp)=>{
if(resp.status){

    setProcessing(false)
    // setError(resp.detail)
    // setOpen1(true)
    props.Reload(resp.detail)
}
else{
    setProcessing(false)

    setError(resp.detail)
    setOpen(true)


}
}

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)


}


const Cancel=()=>{
  setActive('')
  setText('')
  setActiveVal1('')
  setActive1(false)
  setActiveI('')
  setData({
    ...data,image:''
  })
  setEditor('')
  setActiveS('')



}



const quillChange=(val)=>{
  setData(data => ({ ...data, about_event:val}))
  
 }


const dropDownVal=(val)=>{

    if(active1==='category'){
        setData({
            ...data,category:val
        })
        setActive1(false)
    }
    if(active1==='event_type'){
        setData({
            ...data,event_type:val
        })
        setActive1(false)
    }
    if(active1==='event_mode'){
        setData({
            ...data,event_mode:val
        })
        setActive1(false)
    }
    if(active1==='platform'){
        setData({
            ...data,platform:val
        })
        setActive1(false)
    }
 
 

}





const ImageCover1=(img,val)=>{
    
  
  
      
  if(img&&img.name)

    if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  {
    
    if(img.size/1048576 <4){
      setActiveI(val)

setData({...data,image:img})  

  }
     else{
       setError('File should be <4MB')
       setOpen(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen(true)

   }
 
}

const Active1=(val)=>{

    if(val===active1){
        setActive1('')
    }
    setActive1(val)

}

const checkUrl=()=>{
  if(data.event_url&&data.event_url.length>0){
    fetch(live+`event-url?q=${data.event_url}`).then((res)=>res.json()).then(resp=>urlRes(resp))

  }
}

const urlRes=(val)=>{
  if(val.status){
setError1('')
  }
  else{
    setError1(val.detail)
    
  }
}
const changeUrl=(val)=>{
  setData({
    ...data,event_url:val.replace(' ','_')
  })

}

  return (
<div>
<Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
<Grid style={{minHeight:'80vh',paddingBottom:'5vh',paddingTop:'17vh',alignContent:'flex-start'}} container>
    
<Grid item style={{marginBottom:'2vh'}} sm={10} xs={10}>
     <Typography className='txt_evd'>
   
Create New Event
     </Typography>
     </Grid> 
     <Grid style={{textAlign:'center'}} item sm={2} xs={2}>
     <Chip style={{marginBottom:'2vh'}} onClick={()=>props.eventChange()}   className='chip_evd' label={'Cancel'} />

     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
 <TextField value={data.event_name} onChange={(e)=>setData({...data,event_name:e.target.value})} label='Event Name' placeholder='Enter event name' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 


     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <TextField multiline minRows={1} value={data.event_description} onChange={(e)=>setData({...data,event_description:e.target.value})} label='Event Description' placeholder='Enter event description' className='txt_field_evd' />

     </Grid> 

     </Grid> 





     <Grid container style={{paddingTop:'3vh',justifyContent:'center'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
   <Grid     item sm={12} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Event Category
</Typography>
  <Chip

label={data.category?data.category:''}
onClick={()=>Active1('category')}
onDelete={()=>Active1('category')}
deleteIcon={active1==='category'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='category'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {CategoriesMain.map((val,idx)=>
  <li onClick={()=>dropDownVal(val.category)} key={idx} className="list_filter">
  {val.category}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
     </Grid> 
  
     </Grid> 

     <Grid container style={{paddingTop:'3vh',justifyContent:'center'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
   <Grid     item sm={12} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Event Type
</Typography>
  <Chip

label={data.event_type?data.event_type:''}
onClick={()=>Active1('event_type')}
onDelete={()=>Active1('event_type')}
deleteIcon={active1==='event_type'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='event_type'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh',justifyContent:'center'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
   <Grid     item sm={12} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Event Mode
</Typography>
  <Chip

label={data.event_mode?data.event_mode:''}
onClick={()=>Active1('event_mode')}
onDelete={()=>Active1('event_mode')}
deleteIcon={active1==='event_mode'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='event_mode'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Mode.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
     </Grid> 
  
     </Grid> 

      <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item style={{textAlign:activeI==='image'&&data.image? '-webkit-center':'justfy'}} sm={8} xs={8}>


   {activeI==='image'&&data.image? <Card  sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={URL.createObjectURL(data.image)}
        alt="Event"
      />
      </Card>:  <Typography className='text1_evd'>
  Upload Image
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',display:'flex'}} xs={4}>
     <div  style={{display:'flex', justifyContent:'space-around' , backgroundColor: 'var(--pinkColor)',borderRadius:'30px',height:'33px',width:'56%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image&&activeI===`image`?'Uploaded':'Upload Image'}</label>
                  </div>

                  <input type={'file'} id={`file`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0],`image`)}/><br />
         
                  {  activeI===`image`? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
  
    
     </Grid>
     </Grid> 
     
   
     
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Event Start Date"
        value={data.start_date}
        
        onChange={(newValue) => {
          setData({...data,start_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Event End Date"
        value={data.end_date}
        onChange={(newValue) => {
          setData({...data,end_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Event Start Time"
        value={data.start_time}
        onChange={(newValue) => {
          setData({...data,start_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Event End Time"
        value={data.end_time}
        onChange={(newValue) => {
          setData({...data,end_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.event_organiser} onChange={(e)=>setData({...data,event_organiser:e.target.value})} label='Event Organiser' placeholder='Enter event organiser' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.addressLine1} onChange={(e)=>setData({...data,addressLine1:e.target.value})} label='Event Address' placeholder='Enter address' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.city} onChange={(e)=>setData({...data,city:e.target.value})} label='Event City' placeholder='Enter city' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.stat} onChange={(e)=>setData({...data,stat:e.target.value})} label='Event State' placeholder='Enter state' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.country} onChange={(e)=>setData({...data,country:e.target.value})} label='Event Country' placeholder='Enter country' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.pincode} onChange={(e)=>setData({...data,pincode:e.target.value})} label='Event Pincode' placeholder='Enter pincode' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.map_url} onChange={(e)=>setData({...data,map_url:e.target.value})} label='Event Map Url' placeholder='Enter map url' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.iframe_url} onChange={(e)=>setData({...data,iframe_url:e.target.value})} label='Event Iframe Map Url' placeholder='Enter iframe map url' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField  error={error1?true:false} helperText={error1} onBlur={()=>checkUrl()} value={data.event_url} onChange={(e)=>changeUrl(e.target.value)} label='Event  Url' placeholder='Enter  url' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     
  
     
    
  

     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Typography style={{textAlign:'center'}} className='text1_evd'>
About Event 
  
     </Typography>
</Grid>
     {<MyEditorS quillChange={quillChange} editor={editor}  />}

     </Grid> 
   
     </Grid> 
     <Grid container style={{paddingTop:'3vh',alignSelf:'flex-end'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.tag} onChange={(e)=>setData({...data,tag:e.target.value})} label='Event Tag' placeholder='Enter event tag' className='txt_field_evd' />
     </Grid> 
  
     </Grid>
     
  
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>



<Grid item sm={12} xs={12}>
<Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Social Media Platform
</Typography>
  <Chip

label={data.platform?data.platform:''}
onClick={()=>Active1('platform')}
onDelete={()=>Active1('platform')}
deleteIcon={active1==='platform'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid></Grid>
  {active1==='platform'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Platform.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}




     <TextField value={data.social_url} onChange={(e)=>setData({...data,social_url:e.target.value})} label='Social Media Url' className='txt_field_evd' style={{marginTop:'2vh'}} />

     </Grid> 
    
     </Grid>

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.meta_title} onChange={(e)=>setData({...data,meta_title:e.target.value})} label='Event Meta Title' placeholder='Enter meta title' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.meta_description} onChange={(e)=>setData({...data,meta_description:e.target.value})} label='Event Meta Description' placeholder='Enter meta description' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.meta_keywords} onChange={(e)=>setData({...data,meta_keywords:e.target.value})} label='Event Meta Keywords' placeholder='Enter meta keywords' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
<Button onClick={()=>createNew()} style={{marginTop:'2vh'}} className='btn_evd2' variant='contained'>
{processing?'Submitting':'Create Event'}
</Button>
     </Grid> 
   
       </Grid>

        
        </div>
  )
}
