import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore' ;
import {useCookies} from 'react-cookie'

import Accordion from '@mui/material/Accordion';
import{ AccordionSummary,AccordionDetails} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './D6_Sl_Drawer.css'
export default function TemporaryDrawer(props) {
  const[auth,setAuth,deleteAuth]=useCookies(['auth-happ'])

  const [open,setOpen]=React.useState(true)


  

  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
const signoutClicked=()=>{
  deleteAuth(['auth-happ'])
  window.location.href='/'
}
  const list = (anchor) => (
    <Box
      className='box_W'
      role="presentation"
      onClick={Open}
      onKeyDown={Open} 
      
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon style={{cursor:'pointer',paddingBottom:'2px',color:'var(--redColor)'}}>
      <ArrowForwardIcon  />
      </ListItemIcon>
      </ListItem>


    


        {(['HOME','XPLOR']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='HOME'?'/':text=='XPLOR'?'/explore':'/'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}

    
        {!auth['auth-happ']?
        
        (['SIGN IN / SIGN UP' ]).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='CONTACT US'?'/contactus':text=='SIGN IN / SIGN UP'?'/signin':text}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        )):<Accordion>
        <AccordionSummary 
        // style={{margin:'15px 10px'}}
          expandIcon={<ExpandMoreIcon style={{color:'var(--redColor)'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>MY ACCOUNT</Typography>
                    </AccordionSummary>

          <AccordionDetails>
        <Typography className='typo4' onClick={()=>window.location.href="/dashboard"}>Dashboard</Typography>

    <Typography className='typo4' onClick={()=>signoutClicked()}>Signout</Typography>
   
  
          </AccordionDetails>
        
          </Accordion>}
     
    
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={'right'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}