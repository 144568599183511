import {  Card, CardContent, CardMedia,Button, Chip, CircularProgress, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './eventDash.css'
import { img_link, live } from '../../App';
import { MyEditorS, MyEditorS2 } from './editor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CategoriesMain, Categories_Explore, Events_Mode, Events_Platform, Events_Type, SearchCategoriesExplore } from '../homepage/homeData';
import { TimePicker } from '@mui/x-date-pickers';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {useCookies} from 'react-cookie'

export default function New_Explore_Dash(props) {
    const[auth,setAuth]=useCookies(['auth-happ'])

const [data,setData]=useState({
})
const [text,setText]=useState('')

const [processing,setProcessing]=useState(false)
const [editor,setEditor]=useState('')
const [editor1,setEditor1]=useState('')

const [active,setActive]=useState('')
const [active1,setActive1]=useState('')
const [activeI,setActiveI]=useState('')

const [activeVal1,setActiveVal1]=useState('')
const [error,setError]=useState('')
const [activeS,setActiveS]=useState('')
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)

const date1=new Date()

var dd = data&&data.start_date?String(data.start_date.$d.getDate()).padStart(2, '0'):date1.getDate().toString().padStart(2,'0');
var mm = data&&data.start_date?String(data.start_date.$d.getMonth() + 1).padStart(2, '0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy = data&&data.start_date?data.start_date.$d.getFullYear():date1.getFullYear();

let today = yyyy+'-'+mm+'-'+dd;


var dd1 = data&&data.end_date?String(data.end_date.$d.getDate()).padStart(2, '0'):date1.getDate().toString().padStart(2,'0');
var mm1 = data&&data.end_date?String(data.end_date.$d.getMonth() + 1).padStart(2, '0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy1 = data&&data.end_date?data.end_date.$d.getFullYear():date1.getFullYear();

let today1 = yyyy1+'-'+mm1+'-'+dd1;


var dd2 = data&&data.expiry_date?String(data.expiry_date.$d.getDate()).padStart(2, '0'):'';
var mm2 = data&&data.expiry_date?String(data.expiry_date.$d.getMonth() + 1).padStart(2, '0'):''; //January is 0!
var yyyy2 = data&&data.expiry_date?data.expiry_date.$d.getFullYear():'';

let today2 = yyyy2+'-'+mm2+'-'+dd2;
let time1=data.start_time?(data.start_time.$d.getHours()+':'+data.start_time.$d.getMinutes()):date1.getHours()+':'+date1.getMinutes();
let time2=data&&data.end_time?(data.end_time.$d.getHours()+':'+data.end_time.$d.getMinutes()):date1.getHours()+':'+date1.getMinutes();

const createNew=()=>{
    setProcessing(true)
    const formdata=new FormData()
    formdata.append('explore_name',data.explore_name)
    formdata.append('explore_description',data.explore_description)
    formdata.append('explore_organiser',data.explore_organiser)
    formdata.append('map_url',data.map_url)

    formdata.append('categories',data.category)
    formdata.append('userID',auth['auth-happ'].user_id)
    formdata.append('images',data.image)
    formdata.append('tags',data.tag)
    formdata.append('explore_type',data.explore_type)
    formdata.append('explore_mode',data.explore_mode)
    formdata.append('start_date',today)
    formdata.append('end_date',today1)
    formdata.append('start_time',time1)
    formdata.append('end_time',time2)
    formdata.append('explore_url',data.explore_url)

    formdata.append('addressLine1',data.addressLine1)
    formdata.append('city',data.city)
    formdata.append('stat',data.stat)
    formdata.append('country',data.country)
    formdata.append('pincode',data.pincode)
    formdata.append('platform',data.platform)
    formdata.append('social_url',data.social_url)
    formdata.append('overview',data.overview)
    formdata.append('offer_name',data.offer_name)
    formdata.append('offer_heading',data.offer_heading)
    formdata.append('offer_description',data.offer_description)
    formdata.append('offer_expiry',today2)
    formdata.append('blog',data.blog)










    formdata.append('meta_title',data.meta_title)
    formdata.append('meta_description',data.meta_description)

    formdata.append('meta_keywords',data.meta_keywords)

fetch(live+'explore',
{
    method:'POST',
    body:formdata
}

).then((res)=>res.json()).then(resp=>newEventResp(resp))

}

const newEventResp=(resp)=>{
if(resp.status){

    setProcessing(false)
    // setError(resp.detail)
    // setOpen1(true)
    props.Reload(resp.detail)
}
else{
    setProcessing(false)

    setError(resp.detail)
    setOpen(true)


}
}

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)


}


const Cancel=()=>{
  setActive('')
  setText('')
  setActiveVal1('')
  setActive1(false)
  setActiveI('')
  setData({
    ...data,image:''
  })
  setEditor('')
  setActiveS('')



}



const quillChange=(val)=>{
  setData(data => ({ ...data,overview:val}))
  
 }
 const quillChange1=(val)=>{
    setData(data => ({ ...data,blog:val}))
    
   }


const dropDownVal=(val)=>{

    if(active1==='category'){
        setData({
            ...data,category:val
        })
        setActive1(false)
    }
    if(active1==='explore_type'){
        setData({
            ...data,explore_type:val
        })
        setActive1(false)
    }
    if(active1==='explore_mode'){
        setData({
            ...data,explore_mode:val
        })
        setActive1(false)
    }
    if(active1==='platform'){
        setData({
            ...data,platform:val
        })
        setActive1(false)
    }
 
 

}




const ImageCover1=(img,val)=>{
    
  
  
      
  if(img&&img.name)

    if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  {
    
    if(img.size/1048576 <4){
      setActiveI(val)

setData({...data,image:img})  

  }
     else{
       setError('File should be <4MB')
       setOpen(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen(true)

   }
 
}

const Active1=(val)=>{

    if(val===active1){
        setActive1('')
    }
    setActive1(val)

}

const checkUrl=()=>{
  if(data.explore_url&&data.explore_url.length>0){
    fetch(live+`explore-url?q=${data.explore_url}`).then((res)=>res.json()).then(resp=>urlRes(resp))

  }
}

const urlRes=(val)=>{
  if(val.status){
setError('')
  }
  else{
    setError(val.detail)
  }
}
const changeUrl=(val)=>{
  setData({
    ...data,explore_url:val.replace(' ','_')
  })

}

  return (
<div>
<Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
<Grid style={{minHeight:'80vh',paddingBottom:'5vh',paddingTop:'17vh',alignContent:'flex-start'}} container>
    
<Grid item style={{marginBottom:'2vh'}} sm={10} xs={10}>
     <Typography className='txt_evd'>
   
Create New Entity
     </Typography>
     </Grid> 
     <Grid style={{textAlign:'center'}} item sm={2} xs={2}>
     <Chip style={{marginBottom:'2vh'}} onClick={()=>props.eventChange()}   className='chip_evd' label={'Cancel'} />

     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
 <TextField value={data.explore_name} onChange={(e)=>setData({...data,explore_name:e.target.value})} label='Entity Name' placeholder='Enter entity name' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 


     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <TextField multiline minRows={1} value={data.explore_description} onChange={(e)=>setData({...data,explore_description:e.target.value})} label='Entity Description' placeholder='Enter entity description' className='txt_field_evd' />

     </Grid> 

     </Grid> 





     <Grid container style={{paddingTop:'3vh',justifyContent:'center'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
   <Grid     item sm={12} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Entity Category
</Typography>
  <Chip

label={data.category?data.category:''}
onClick={()=>Active1('category')}
onDelete={()=>Active1('category')}
deleteIcon={active1==='category'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='category'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Categories_Explore.map((val,idx)=>
  <li onClick={()=>dropDownVal(val.category)} key={idx} className="list_filter">
  {val.category}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
     </Grid> 
  
     </Grid> 

     <Grid container style={{paddingTop:'3vh',justifyContent:'center'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
   <Grid     item sm={12} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Entity Type
</Typography>
  <Chip

label={data.explore_type?data.explore_type:''}
onClick={()=>Active1('explore_type')}
onDelete={()=>Active1('explore_type')}
deleteIcon={active1==='explore_type'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='explore_type'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh',justifyContent:'center'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
   <Grid     item sm={12} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Entity Mode
</Typography>
  <Chip

label={data.explore_mode?data.explore_mode:''}
onClick={()=>Active1('explore_mode')}
onDelete={()=>Active1('explore_mode')}
deleteIcon={active1==='explore_mode'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='explore_mode'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Mode.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
     </Grid> 
  
     </Grid> 

      <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item style={{textAlign:activeI==='image'&&data.image? '-webkit-center':'justfy'}} sm={8} xs={8}>


   {activeI==='image'&&data.image? <Card  sx={{ maxWidth: 300 }}>
      <CardMedia
      className='img_card'
        component="img"
        height="160"
        image={URL.createObjectURL(data.image)}
        alt="Entity"
      />
      </Card>:  <Typography className='text1_evd'>
  Upload Image
  
     </Typography>}
     </Grid> 
     <Grid item sm={4} style={{textAlign:'start',display:'flex'}} xs={4}>
     <div  style={{display:'flex', justifyContent:'space-around' , backgroundColor: 'var(--pinkColor)',borderRadius:'30px',height:'33px',width:'56%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image&&activeI===`image`?'Uploaded':'Upload Image'}</label>
                  </div>

                  <input type={'file'} id={`file`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0],`image`)}/><br />
         
                  {  activeI===`image`? 
   <Chip  onClick={()=>Cancel()}   className='chip_evd2' label={'Cancel'} />
:null}
  
    
     </Grid>
     </Grid> 
     
   
     
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Entity Start Date"
        value={data.start_date}
        
        onChange={(newValue) => {
          setData({...data,start_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Entity End Date"
        value={data.end_date}
        onChange={(newValue) => {
          setData({...data,end_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Entity Start Time"
        value={data.start_time}
        onChange={(newValue) => {
          setData({...data,start_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Entity End Time"
        value={data.end_time}
        onChange={(newValue) => {
          setData({...data,end_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.explore_organiser} onChange={(e)=>setData({...data,explore_organiser:e.target.value})} label='Entity Organiser' placeholder='Enter entity organiser' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.addressLine1} onChange={(e)=>setData({...data,addressLine1:e.target.value})} label='Entity Address' placeholder='Enter address' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.city} onChange={(e)=>setData({...data,city:e.target.value})} label='Entity City' placeholder='Enter city' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.stat} onChange={(e)=>setData({...data,stat:e.target.value})} label='Entity State' placeholder='Enter state' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.country} onChange={(e)=>setData({...data,country:e.target.value})} label='Entity Country' placeholder='Enter country' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.pincode} onChange={(e)=>setData({...data,pincode:e.target.value})} label='Entity Pincode' placeholder='Enter pincode' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.map_url} onChange={(e)=>setData({...data,map_url:e.target.value})} label='Entity Map Url' placeholder='Enter map url' className='txt_field_evd' />
     </Grid> 
  
     </Grid>
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField  error={error?true:false} helperText={error} onBlur={()=>checkUrl()} value={data.explore_url} onChange={(e)=>changeUrl(e.target.value)} label='Entity  Url' placeholder='Enter  url' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     
  
     
    
  

     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Typography style={{textAlign:'center'}} className='text1_evd'>
Entity Overview 
  
     </Typography>
</Grid>
     {<MyEditorS quillChange={quillChange} editor={editor}  />}

     </Grid> 
   
     </Grid> 


     {/* <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Typography className='text1_evd'>
Entity Offer 
  
     </Typography>
</Grid>
<Grid container sm={12} xs={12}>
    <Grid style={{paddingTop:'3vh'}} item sm={6} xs={12}>
    <TextField value={data.offer_name} onChange={(e)=>setData({...data,"offer_name":e.target.value})} label='Offer' placeholder='enter offer i.e. 70% OFF ' className='txt_field_evd' />

    </Grid>
  
    <Grid item  style={{paddingTop:'3vh'}}sm={6} xs={12}>
    <TextField value={data.offer_heading} onChange={(e)=>setData({...data,"offer_heading":e.target.value})} label='Offer Name' placeholder='offer name' className='txt_field_evd' />

    </Grid>
    <Grid item style={{paddingTop:'3vh'}} sm={6} xs={12}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Offer Expiry Date"
        value={data.expiry_date?data.expiry_date:new Date()}
        onChange={(newValue) => {
          setData({...data,expiry_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
    </Grid>
    <Grid item style={{paddingTop:'3vh'}} sm={6} xs={12}>
    <TextField multiline minRows={1} value={data.offer_description} onChange={(e)=>setData({...data,"offer_description":e.target.value})} label='Offer Decription' placeholder='Offer description' className='txt_field_evd' />

    </Grid>
     
</Grid>

   
     </Grid>  */}


     <Grid container style={{paddingTop:'3vh'}} sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Typography style={{textAlign:'center'}} className='text1_evd'>
Entity Blog 
  
     </Typography>
</Grid>
     {<MyEditorS2 quillChange1={quillChange1} editor1={editor1}  />}

     </Grid> 
   
     </Grid> 
     <Grid container style={{paddingTop:'3vh',alignSelf:'flex-end'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.tag} onChange={(e)=>setData({...data,tag:e.target.value})} label='Entity Tag' placeholder='Enter entity tag' className='txt_field_evd' />
     </Grid> 
  
     </Grid>
     
  
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} xs={12}>



<Grid item sm={12} xs={12}>
<Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography style={{paddingRight:'20px'}}>
    Social Media Platform
</Typography>
  <Chip

label={data.platform?data.platform:''}
onClick={()=>Active1('platform')}
onDelete={()=>Active1('platform')}
deleteIcon={active1==='platform'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid></Grid>
  {active1==='platform'?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Events_Platform.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}




     <TextField value={data.social_url} onChange={(e)=>setData({...data,social_url:e.target.value})} label='Social Media Url' className='txt_field_evd' style={{marginTop:'2vh'}} />

     </Grid> 
    
     </Grid>

     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.meta_title} onChange={(e)=>setData({...data,meta_title:e.target.value})} label='Entity Meta Title' placeholder='Enter meta title' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.meta_description} onChange={(e)=>setData({...data,meta_description:e.target.value})} label='Entity Meta Description' placeholder='Enter meta description' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid container style={{paddingTop:'3vh'}} sm={6} xs={12}>
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
 <TextField value={data.meta_keywords} onChange={(e)=>setData({...data,meta_keywords:e.target.value})} label='Entity Meta Keywords' placeholder='Enter meta keywords' className='txt_field_evd' />
     </Grid> 
  
     </Grid> 
     <Grid item sm={12} style={{alignSelf:'center'}} xs={12}>
<Button onClick={()=>createNew()} style={{marginTop:'2vh'}} className='btn_evd2' variant='contained'>
{processing?'Submitting':'Create Entity'}
</Button>
     </Grid> 
   
       </Grid>

        
        </div>
  )
}
