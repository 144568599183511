import { Button, Card, CardContent, CardMedia, CircularProgress, Container, Dialog, Grid, IconButton, InputAdornment, OutlinedInput, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState,useRef} from 'react'
import './home_top.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PageviewIcon from '@mui/icons-material/Pageview';
import { CategoriesMain, SearchCategories } from '../homeData';
import { img_link, live } from '../../../App';
import moment from 'moment/moment';
import SignIND from '../../auth/signinD';
import {useCookies} from 'react-cookie'

import { ChevronLeft } from '@mui/icons-material';

export default function Home_Top() {

  const myRef = useRef(null)

  const[activeC,setActiveC]=useState('All')
  const [country,setCountry]=useState('')
  const[processing,setProcessing]=useState(true)
const [events,setEvents]=useState([])
const [liked,setLiked]=useState(false)
const [open,setOpen]=useState(false)

const [openD,setOpenD]=useState(false)
const[reload,setReload]=useState(0)
const[auth]=useCookies(['auth-happ'])
const [address,setAddress]=useState('')

const [suggestions,setSuggestions]=useState('')


// let filter=events.filter(val=>val.tags.find(val=>val.tag.toLowerCase().includes(activeC.toLowerCase())))
// console.log(filter,activeC)


let filter2=events&&events[0]&&activeC==='All'?events:events&&events[0]&&activeC!=='All'&&activeC!=='Today'&&activeC!=='This weekend'?(events.filter(val=>(val.category.toLowerCase().includes(activeC.toLowerCase())||val.event_type.toLowerCase().includes(activeC.toLowerCase())||val.event_mode.toLowerCase().includes(activeC.toLowerCase())
||val.event_name.toLowerCase().includes(activeC.toLowerCase())||val.event_organiser.toLowerCase().includes(activeC.toLowerCase())||val.tags&&val.tags[0]&&val.tags.find(val=>val.tag.toLowerCase().includes(activeC.toLowerCase()))||val.event_mode.toLowerCase().includes(activeC.toLowerCase())||val.addressLine1.toLowerCase().includes(activeC.toLowerCase())||val.city.toLowerCase().includes(activeC.toLowerCase())||val.stat.toLowerCase().includes(activeC.toLowerCase())||val.country.toLowerCase().includes(activeC.toLowerCase())))):events&&events[0]&&activeC=='Today'?(events.filter(val=>(moment(new Date(val.start_date)).format("DD MMM YYYY"))===((moment(new Date()).format("DD MMM YYYY")))||(moment(new Date(val.end_date)).format("DD MMM YYYY"))===((moment(new Date()).format("DD MMM YYYY")))
)):events&&events[0]&&activeC=='This weekend'?(events.filter(val=>
  (moment(new Date(val.start_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (6-new Date().getDay())))).format("DD MMM YYYY")))||
  (moment(new Date(val.end_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (6-new Date().getDay())))).format("DD MMM YYYY")))||
  (moment(new Date(val.start_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (7-new Date().getDay())))).format("DD MMM YYYY")))||(moment(new Date(val.end_date)).format("DD MMM YYYY"))===((moment((new Date().getDay()===0)||(new Date().getDay()===6)?(new Date().setDate(new Date().getDate() + 7)):(new Date().setDate(new Date().getDate() + (7-new Date().getDay())))).format("DD MMM YYYY")))
)):''


console.log(filter2)



  useEffect(() => {
 fetch('https://ipapi.co/json').then(res=>res.json()).then((resp)=>Country(resp))
  }, [])
  
const Country=(val)=>{
  setCountry(val)
  fetch(live+`events?city=${val.city}&state=${val.region}`).then(res=>res.json()).then((resp)=>Events(resp))


}

  
useEffect(() => {

}, [])
useEffect(() => {
  if(reload>0){
    fetch(live+`events?city=${address.city?address.city:country.city}&state=${address.city?address.stat:country.state}`).then((res)=>res.json()).then(resp=>Events(resp))
   
  }

   },[reload])
const Events=(val)=>{
  setProcessing(false)
  setLiked('')
setEvents(val.data)
}

const Follow=(val)=>{
  if(auth&&auth['auth-happ']){
    setLiked(val)
    const formdata =new FormData()
    formdata.append('liked_by',auth['auth-happ'].user_id)
    formdata.append('event',val)
fetch(live+'event-url',
{
  method:'PUT',
  body:formdata
}).then(resp=>resp.json()).then(res=>likeRes(res))
  }
  else{
    setOpenD(true)
  }
}

const likeRes=()=>{
  setReload(reload+1)
}

const handleClose=()=>{
  setOpenD(false)
}

const Address=(val)=>{
  setOpen(true)
  // setAddress(val)
  const autocomplete = new window.google.maps.places.AutocompleteService();
  autocomplete.getPlacePredictions({
    input:val
  }).then((res)=>setSuggestions(res.predictions))

}





 const Address1=(val)=>{
  setOpen(false)
  setProcessing(true)

  fetch(live+`events?city=${val.terms[0].value}&state=${val.terms[1].value}`).then((res)=>res.json()).then(resp=>Events(resp))

  setAddress({
    ...address,city:val.terms[0].value,stat:val.terms[1].value

  })

 }


 const ActiveC=(val)=>{
  setActiveC(val)
  executeScroll()
 }
const Search=(val)=>{

  if(val.length>4){
    setActiveC(val)
  }
else{
  setActiveC('All')

}

}

const executeScroll=()=>{

  if(myRef&&myRef.current)
  myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

}

  if (!processing){
    return (
      <div>
          <div>
              <img src={require('../../../images/EV_2.jpg')} alt='home' className='home_topI' />
          </div>

          <Dialog
      className='dialog_signin'
      disableScrollLock={ true }
      open={openD}
      style={{textAlign:'center',margin:10}}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<SignIND handleClose={handleClose} />

    </Dialog>
          <Grid className='grid_home'   item sm={12} xs={12}>
          <div class="max-width">
            <div class="crousel-content">
            <h2>Let's Make Live Happen
  </h2>
              <p>
              Millions of live events and discover can't-miss concerts, games, theater and more..
              </p>
            
            </div>
          </div>
          <Grid container sm={12} xs={12}>
            <Grid item className='grid_locations'  sm={4} xs={12}>
          <OutlinedInput          style={{width:'100%'}}
  
              // id="outlined-adornment-password"
         
            className='tf_home'
            placeholder='Search for location'
         
            onChange={(e)=>Address(e.target.value)}
            endAdornment={<InputAdornment position="end"><IconButton
            aria-label="location"
            // onClick={console.log('1')}
            edge="end"
          >
       
           {!open?<LocationOnIcon style={{color:'white'}} />:
           <ChevronLeft style={{color:'white'}} onClick={()=>setOpen(false)} />}
          </IconButton></InputAdornment>}
          >
            
            </OutlinedInput>

{suggestions&&suggestions[0]&&open?<div  style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:'100%',alignSelf:'start',alignContent:'start',borderRadius:5,maxHeight:400,overflowY:'scroll'}}>
{suggestions.map((suggestion,idx)=>(

  <Grid onClick={()=>Address1(suggestion)} key={idx}  className='cont_search' container>

<Grid item  sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='main_txt'>{suggestion.terms[0].value}</Typography>
  </Grid>
 <Grid item sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='sub_txt'>{suggestion.description}</Typography></Grid>
  
 
  
  </Grid>)
)}  
</div>:null}  

            </Grid>
            <Grid item  className='grid_search'  sm={8} xs={12}>
            <OutlinedInput 
            style={{width:'99%'}}
              id="outlined-adornment-password"
            className='tf_home'
            placeholder='Search for organizers, venues and events'
              
onChange={(e)=>Search(e.target.value)}

            startAdornment={<InputAdornment position="start"><IconButton
            aria-label="location"
            // onClick={console.log('1')}
            edge="start"
          >
         
           <PageviewIcon style={{color:'white'}} />
          </IconButton></InputAdornment>}
          endAdornment={<InputAdornment position="end"><IconButton
          aria-label="location"
          // onClick={console.log('1')}
          edge="end"
        >
         <Button variant='contained' onClick={()=>executeScroll()} className='btn_search'>search</Button>
        </IconButton></InputAdornment>}
          />
            </Grid>
          </Grid>
          </Grid>
          {/* <input ref={inputRef} /> */}
          <Container style={{maxWidth:'1350px'}} item sm={12} xs={12}>
           <Grid container> 
            <Paper elevation={6} className='ppr_1'>
  <Grid container >
    <Grid item sm={12} xs={12}>
  <Typography className='ppr_1_head'>
  Do events help you find connection?
  </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
  <Typography className='ppr_1_text'>
  {/* Help us understand how attending events contributes to building social connection by taking a short survey before and after your next event.<a href='/'> Start here</a>. */}
  
   As events helps to find connection to subscribe notifications.<a href='/'>Register here</a>.
  </Typography>
    </Grid>
  </Grid>
            </Paper>
            </Grid>
            <Grid style={{paddingTop:'3vh'}} item sm={12} xs={12}>
            <Typography className='typo_happ'>
              Happening Around <span>{address.city?address.city:country.city}</span><i class="fas fa-map-marker-alt"></i>
            </Typography>
            </Grid>
            <Grid container style={{paddingTop:'2vh'}} >
              {SearchCategories.map((val,idx)=>
             <Typography key={idx} onClick={()=>ActiveC(val)}  className={activeC===val?'typo_catA':'typo_cat'}>
  {val}
             </Typography> )}
            </Grid>
  
  
            <Grid item sm={12} xs={12}>
  <Typography className='trending_head'>
  Check out trending categories
  </Typography>
    </Grid>

    
    <Grid container style={{marginBottom:'20px'}} >
      {CategoriesMain.map((val,idx)=>
      <Grid className='grid_card' key={idx} item sm={3} xs={6}>
      <Card onClick={()=>ActiveC(val.category)}  sx={{ maxWidth: 300 }}>
        <CardMedia
        className='img_card'
          component="img"
          height="160"
          image={val.image}
          alt="Event"
        />
          <CardContent className='card_category'>
          <Typography className='typo_card_category' gutterBottom variant="h5" component="div">
            {val.category}
          </Typography>
          </CardContent>
        </Card>
      </Grid>)}
    </Grid>
    <div ref={myRef}>

{  
(activeC=='All'&&events[0]||(filter2&&filter2.length>0))?<Grid innerRef={myRef}  item sm={12} xs={12}>
  <Typography  className='trending_head'>
 {activeC} Events 
  </Typography>
    </Grid>:  
<Grid item  innerRef={myRef} style={{color:'var(--pinkColor)',textAlign:'center'}} sm={12} xs={12}>
  <Typography className='trending_head'>
 No {activeC=='All'?'':activeC} Events found
  </Typography>
    </Grid>}
    </div>

   {activeC=='All'||(filter2&&filter2.length>0) ?<Grid container style={{marginBottom:'20px'}} >


      {(activeC!=='All'?filter2:events).map((val,idx)=>
      {
// console.log(moment(val.start_time))

        return(
        <Grid className='grid_card' key={idx} item sm={3} xs={12}>
      <Card  className='card_event' sx={{ maxWidth: 300 }}>
        <CardMedia 
        onClick={()=>window.open(`/events/${val.event_url}`)}
        // className='img_card'
          component="img"
          height="160"
          image={img_link+val.images[0].image}
          alt="Event"
        />
          <CardContent >
            <div  className='like_event'>
            {(val.likes!=null&&auth['auth-happ']&&val.likes.length>0&&val.likes.find(val=>val.user_id===auth['auth-happ'].user_id)||liked===val.event_id)?<i class="fas fa-heart icon_event" ></i>:<i class="far fa-heart icon_event" onClick={()=>Follow(val.event_id)}></i>}
          </div>
          <div onClick={()=>window.open(`/events/${val.event_url}`)}>
          <Typography className='typo_eventHead'>
{val.event_name}          </Typography>
          <Typography className='typo_eventTime'>
          {moment(val.start_date).format("MMMM Do YYYY")+' - '+moment(val.end_date).format("MMMM Do YYYY")}
            </Typography>
            <Typography style={{paddingTop:'5px'}} className='typo_eventTime'>
            {/* {moment(val.start_time._i).format('LT').toString()+' - '+moment(val.end_time._i).format('LT').toString()} */}
            {val.start_time.slice(0,5).toString()+' - '+val.end_time.slice(0,5).toString()}
            {/* {new Date(val.start_time).getHours().toString()+':'+new Date(val.start_time).getMinutes().toString()+' - '+new Date(val.end_time).getHours().toString()+':'+new Date(val.end_time).getMinutes().toString()} */}
            </Typography>
            <Typography className='typo_eventAuth'>
            
            {val.event_organiser}
            </Typography>
            <Typography className='typo_eventFollow'>
           <span><i class="far fa-user icon_user"></i></span> {val.show_likes_admin&&liked===val.event_id?val.admin_likes+1:val.show_likes_admin&&liked!==val.event_id?val.admin_likes:!val.show_likes_admin&&liked===val.event_id&&val.likes!=null?val.likes.length+1:!val.show_likes_admin&&liked===val.event_id&&val.likes==null?1:(val.likes!==null&&val.likes.length?val.likes.length:0)} followers
            </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>)})}
    </Grid>:null}
          </Container>
      </div>
    )
  }
  else{
    return(
      <Grid sm={12} style={{textAlign:'center',height:'100vh'}} xs={12} item>
      <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh',color:'var(--pinkColor)'}} />
      </Grid>)
  }

}
