import React from 'react'
import './D6_SL_AF_CSS.css';
import {Grid,Container} from '@mui/material';
import {Link, NavLink} from "react-router-dom";





const D6_SL_AF = () => {

    
    return (
<>


   <div className="D6_AboveFooter_Container">   

 <Container  style={{maxWidth:1200}} fixed className="D6_AF_Grid_Cont">
 <Grid container justify="center" className="D6_AF_Grid">

        <Grid item md={4} xs={12} className="D6_AF_Grid3">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-3">
                <h3 className="D6_AboveFooter_H3">Discover</h3>
                <ul className="D6_AboveFooter_List3">
                <li>
                        <NavLink className=""  to="/">
                        Events                    </NavLink>
                    </li>
                    <li>
                        <NavLink className=""  to="/explore">
                        Xplor                     </NavLink>
                    </li>
                   
                  
                </ul>
             
            </div>
        </Grid>

        <Grid item md={4} xs={12} className="D6_AF_Grid3">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-3">
                <h3 className="D6_AboveFooter_H3">About</h3>
                <ul className="D6_AboveFooter_List3">
                <li>
                <a className="" href="https://www.dec6.in/PrivacyPolicy" target='_blank'>
                            Privacy Policy
                        </a>  </li>
                    <li>
                    <a className="" href="https://www.dec6.in/Terms&Conditions" target='_blank'>
                        Terms & Conditions
                        </a> </li>
                   
                  
                </ul>
             
            </div>
        </Grid>

     
        <Grid item md={4} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H3">Reach Us</h3>
                <div className="D6_AboveFooter_Item5D1">
                 
                <div className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:reach@happ360.com"  className="D6_AboveFooter_Icon_E">
                        reach@happ360.com
                        </a>  
                    </div>
              


                    <div className="Dec6_AboveFooter_SymbolsList1">
                        <a href="https://www.linkedin.com/Happ360"  className="fab fa-instagram circle-icon1 circle-icon-tw aicon"></a>
                        <a href="https://www.facebook.com/Happ360"  className="fab fa-facebook circle-icon1   ">
                      
                      
                        </a>
                        <a href="https://googleplay/Happ360"  className="fab fa-google-play circle-icon1   "></a>
                        <a href="https://apple.store/Happ360"  className="fab fa-app-store-ios circle-icon1   ">

</a>
    
                     
                    </div> 

                
                 
                   
                </div>
            </div>
        </Grid>

    </Grid>   
</Container>  
</div>
</>
    )
}

export default D6_SL_AF
