import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/common/header/header';
import Home from './components/homepage/home';
import ParticularEvent from './components/event/particularEvent';
import Footer from './components/common/footer/footer';
import SignIN from './components/auth/signin';
import Explore from './components/explore/explore';
import ParticularExplore from './components/explore/exploreParticular';
import Dashboard from './components/dashboard/dashboard';
import HeaderB from './components/common/header/headerB';
import ScrollT from './components/common/STTP/scroll_Top';
import D6_SL_AF from './components/common/footer/D6_SL_AF';

// export const live='http://127.0.0.1:8000/'
export const  live='https://d6-happlive.herokuapp.com/'
//export const live='https://d6-prelive.herokuapp.com/'
export const img_link='https://d6-happ.s3.ap-south-1.amazonaws.com/Data/Happ-web/'

function App() {

  var val=window.location.pathname
  
var page1=val.replace('/events/','')
var page2=val.replace('/explore/','')

return (
    <div className="App">

      
      {val.includes('/events/')||val.includes('/explore/')?
      
      
      <BrowserRouter>
      <HeaderB />
    {val.includes('/events/')?
      <ParticularEvent page1={page1} />:<ParticularExplore page2={page2} />}
    

      <Footer />
      </BrowserRouter>: <BrowserRouter>
      <Header />
      <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/e' element={<ParticularEvent />} />
      <Route path='/signin' element={<SignIN />} />
      <Route path='/explore' element={<Explore />} />
      <Route path='/p' element={<ParticularExplore />} />
      <Route path='/dashboard' element={<Dashboard />} />

      </Routes>
      {window.location.pathname==='/dashboard'?null:<><D6_SL_AF />
      <Footer /></>}
      < ScrollT />

      </BrowserRouter>}
    </div>
  );
}

export default App;
