export const SearchCategories=[
    'All','Online','Today','This weekend','Free','Music','Food & Drink','Charity & Causes'
]

export const CategoriesMain=[
    {
       category:'Music',
       image:require('../../images/EV_3.jpg')
    },
    {
        category:'Performing & Visual Arts',
        image:require('../../images/Art.jpg')
     }, {
        category:'Travel & Outdoor',
        image:require('../../images/Travel.jpg')
     },
     {
        category:'Health',
        image:require('../../images/Health.jpg')
     }, {
        category:'Shopping',
        image:require('../../images/Hobby.jpg')
     },
     {
        category:'Business',
        image:require('../../images/Business.jpg')
     },
     {
        category:'Food & Drinks',
        image:require('../../images/Food.jpg')
     },
     {
        category:'Sports & Fitness',
        image:require('../../images/Sports.jpg')
     },
     {
      category:'Festival',
      image:require('../../images/festival.jpg')
   },

   {
    category:'Spiritual',
    image:require('../../images/spirit.jpg')
 },
]

export const Categories_Explore=[
   {
      category:'Grocery',
      image:require('../../images/grocery.jpg')
   },
   {
       category:'Electronics',
       image:require('../../images/Art.jpg')
    }, {
       category:'Travel & Outdoor',
       image:require('../../images/Travel.jpg')
    },
    {
       category:'Health',
       image:require('../../images/Health.jpg')
    }, {
       category:'Lifestyle',
       image:require('../../images/Hobby.jpg')
    },
   //  {
   //     category:'Business',
   //     image:require('../../images/Business.jpg')
   //  },
    {
       category:'Food & Drinks',
       image:require('../../images/Food.jpg')
    },
    {
       category:'Fitness',
       image:require('../../images/Sports.jpg')
    },
    
//     {
//      category:'Festival',
//      image:require('../../images/EV_3.jpg')
//   },

  {
   category:'Education',
   image:require('../../images/education.jpg')
}
]




export const SearchCategoriesExplore=[
   'All','Online','Today','This weekend','Great Deals','Limited Offers','End Of The Season','Most Popular'
]


export const ExploreDataparticular=[
   {
      category:'Food',
      offer:'20% off on entire menu',
      name:'Barbeque Nation',
      image:require('../../images/Food.jpg')
   },
   {
      category:'Lifestyle',
      offer:'New Arrival Sale',
      name:'HR Fashions',
      image:require('../../images/explore.jpg')
   }
   ,
   {
      category:'Electronics',
      offer:'Diwali Bumper',
      name:'Jr Electronics',
      image:require('../../images/explore.jpg')
   }
   ,
   {
      category:'Lifestyle',
      offer:'End Of Season',
      name:'Tommy',
      image:require('../../images/explore.jpg')
   }
   
   
]

export const Events_Mode=[
'Online','Offline'
]
export const Events_Type=[
   'Paid','Free'
]
export const Events_Platform=[
   'Facebook','Snapchat','Linked In','Whatsapp','Instagram'
]

