import React from "react";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css"; 
import { Container, Grid } from "@mui/material";

export function MyEditorS(props)  {
  const {editor}=props
  const placeholder = 'Start writing inner content here...';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
  
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],
  
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link','image','video'],
      [{ color: [] }, { background: [] }],
      
  
      ['clean'],
    ],
  };
  const { quill, quillRef } = useQuill({modules,placeholder});


  


  React.useEffect(() => {
    if (quill) {
      if(editor){
        quill.clipboard.dangerouslyPasteHTML(editor)
      }
      quill.on('text-change', () => {
        props.quillChange(quill.root.innerHTML)

      });
    }
  }, [quill,editor]);







  return (
    <Container>
    <div style={{ width:'auto', height: 400 ,marginBottom:50,paddingBottom:50}}>
      <div ref={quillRef} />
    </div>
    </Container>
  );
};



export function MyEditorS2(props)  {
  const {editor1}=props
  const placeholder = 'Start writing blog here...';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
  
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],
  
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link','image','video'],
      [{ color: [] }, { background: [] }],
      
  
      ['clean'],
    ],
  };
  const { quill, quillRef } = useQuill({modules,placeholder});


  


  React.useEffect(() => {
    if (quill) {
      if(editor1){
        quill.clipboard.dangerouslyPasteHTML(editor1)
      }
      quill.on('text-change', () => {
        props.quillChange1(quill.root.innerHTML)

      });
    }
  }, [quill,editor1]);







  return (
    <Container>
    <div style={{ width:'auto', height: 400 ,marginBottom:50,paddingBottom:50}}>
      <div ref={quillRef} />
    </div>
    </Container>
  );
};